import { useCallback, useEffect, useState } from "react";
import { getSessionUser } from "./session";
import { useMessage } from "./useMessage";


export default function useCurrentUser() {

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])


  const [currentUser, setCurrentUser] = useState(getSessionUser())

  useMessage('sessionUserLoaded', useCallback(() =>  setCurrentUser( getSessionUser() ), []))

  return currentUser
}

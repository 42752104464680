import LoadingSpinner from "@/components/LoadingSpinner";
import classNames from "classnames";

export default function LoadingOverlay ({visible, delay=250}) {

  return <>
    <div className={classNames("loading-overlay", {visible})}>
      <div className="loading-background"/>
      <div className="loading">
        <LoadingSpinner size="medium" />
      </div>
    </div>

    <style jsx>{`
      .loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 9999;
        transition: opacity 400ms ${delay}ms;
        opacity: 0;
        pointer-events: none;
      }
      .loading-overlay.visible {
        pointer-events: auto;
        opacity: 1;
      }
      .loading-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        opacity: 0.7;
      }
      .loading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `}</style>
  </>

}
import { useEffect, useRef, useState } from "react"

export default function useDeviceInfo () {

  const originalHeight = useRef()

  const [info, setInfo] = useState({
    mobile:  false,
    android: false,
    iphone:  false,
    ipad:    false,
    ipod:    false,
    ios:     false,
    touch:   false,
  })

  // FIXME: navigator.platform is deprecated and navigator.userAgent will be
  // changing. Need to switch this code to use more modern approaches.

  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase()

    const android = ua.indexOf("android") > -1
    const iphone  = ua.indexOf("iphone")  > -1
    const ipad    = ua.indexOf("ipad")    > -1 || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    const ipod    = ua.indexOf("ipod")    > -1
    const touch   = 'ontouchend' in document || navigator.maxTouchPoints > 0
    const ios     = (iphone || ipad || ipod)
    const mobile  = (android || ios)
    const mac     = (navigator?.platform || '').toLowerCase().indexOf('mac') === 0
    const safari  = ua.indexOf('safari') > -1 && ua.indexOf('chrome') === -1

    const installed = (
      navigator.standalone === true ||                        // on iOS
      (
        typeof window.matchMedia === 'function' &&
        window.matchMedia('(display-mode: standalone)').matches // on Android
      )
    )

    // "Fix" for the 100VH "feature" of mobile browser (see _app.js for more)
    // Also fixes bug on Chromium desktop where 100vw includes scrollbar width
    const viewport = !!window.visualViewport
    function getWidth  () { return viewport ? window.visualViewport.width  : window.innerWidth  }
    function getHeight () { return viewport ? window.visualViewport.height : window.innerHeight }

    function latestSizing () {
      const width  = getWidth()
      const height = getHeight()

      if (!originalHeight.current) originalHeight.current = height

      const portrait = width < height
      const landscapePhone = mobile && width > height * 1.5
      const keyboardOnScreen = mobile && !landscapePhone && height < originalHeight.current - 100

      return { width, height, portrait, landscapePhone, keyboardOnScreen }
    }

    function updateViewportSize () {
      setInfo(currentInfo => {
        return {
          ...currentInfo,
          ...latestSizing(),
        }
      })
    }

    setInfo({
      android,
      iphone,
      ipad,
      ipod,
      touch,
      ios,
      mobile,
      mac,
      safari,
      installed,
      ...latestSizing(),
    })

    //==========================================================================
    // FIXME: Calling `updateViewportSize` when the viewport resizes causes
    // a bug on Android when tapping on a text box (in the OrgForm control).
    // However, turning this off will break a bunch of other things, including
    // the sizing of Modal windows (which the OrgForm is inside of)
    //==========================================================================

    const resizeTarget = viewport ? window.visualViewport : document
    resizeTarget.addEventListener('resize', updateViewportSize)
    return () => resizeTarget.removeEventListener('resize', updateViewportSize)
  }, [setInfo])

  return info
}

import { useEffect, useState } from "react"
import useApi from "./useApi"

export default function useEnv () {

  const [env, setEnv] = useState({})
  const [envData] = useApi('/env')

  useEffect(() => {
    if (envData) setEnv(envData)
  }, [envData])

  return env
}

import { sleep } from "@/src/misc"
import { useRef, useState } from "react"
import rpc from "@/src/rpc"
import { logout } from "@/src/session"
import { useAppRouter } from "@/src/useAppRouter"
import LoginCodeForm from "./LoginCodeForm"
import { sendMessage } from "@/src/useMessage"
import useRpc, { refreshRpc } from "@/src/useRpc"
import { useEffect } from "react"
import classNames from "classnames"
import Checkbox from "./Checkbox"
import { identifyUser, trackConversion } from "@/src/appAnalytics"
import { refreshCurrentOrg } from "@/src/useCurrentOrg"


export default function VerifyEmailForm ({onClose, defaultEmail, required}) {

  const router = useAppRouter()

  const emailInput = useRef(null)
  const [user] = useRpc('users.me')
  const [email, setEmail] = useState(defaultEmail || '')
  const [wantsMarketingEmails, setWantsMarketingEmails] = useState(false)
  const [error, setError] = useState('')
  const [busy, setBusy] = useState(false)
  const [verified, setVerified] = useState(false)
  const [attempts, setAttempts] = useState(0)
  const [existingEmail, setExistingEmail] = useState(false)
  const [verificationLoginId, setVerificationLoginId] = useState(false)
  const [showEmailTips, setShowEmailTips] = useState(false)

  // Set focus on email input, when form loads
  useEffect(() => {
    if (emailInput.current) {
      emailInput.current.focus()
    }
  }, [])

  // Fire off a conversion event (for analytics)
  useEffect(() => {
    if (!verified) return

    identifyUser({email})
    trackConversion()
  }, [verified, user, email])



  function handleEmailInput(e) {
    const newEmail = e.currentTarget.value.trim()
    setError('')
    setEmail(newEmail)
  }


  async function handleSwitchAccounts (e) {
    e.preventDefault()
    sendMessage('loading', true)
    setExistingEmail(false)
    await logout()
    router.push(`/login?email=${email}`)
  }


  async function handleSubmit (e) {
    e.preventDefault()

    if (email.length === 0) return

    if (!email.includes('@')) {
      setError('Invalid Email Address')
      return
    }

    setError('')
    setAttempts(v => v + 1)
    setBusy(true)
    sendMessage('loading', true)

    try {
      const data = {email: email.toLowerCase(), wantsMarketingEmails}
      const res = await rpc('users.verifyEmail', data, {throwErrors: true})
      const {emailExists, loginId} = res

      if (emailExists) {
        setExistingEmail(true)
      } else {
        // Add a fake delay to give the email time to land in their inbox
        await sleep(2000)
        setVerificationLoginId(loginId)
      }
    } catch (err) {
      setError(err.message || `Couldn't process request`)
    }

    setBusy(false)
    sendMessage('loading', false)
  }


  function handleTryAgain () {
    setShowEmailTips(false)
    setVerificationLoginId(false)
    setExistingEmail(false)
  }


  async function handleVerification () {
    try {
      await rpc('signup.verify')
      setVerified(true)
      sendMessage('loading', false)
      refreshCurrentOrg()
      refreshRpc('users.allForOrg')
      refreshRpc('users.me')
    } catch (err) {
      console.warn('Verification Failed')
      console.error(err)
    }
  }


  async function handleClose () {
    onClose()
  }


  if (verified) {
    return <>
      <div className="double-padded content center-text" style={{maxWidth: 310}}>
        <h2>Verified 👍</h2>
        <div className="spaced">
          <p>
            Your email has been verified.
          </p>
          <p>
            Now you can: connect your social media accounts and start publishing posts.
          </p>
        </div>
        <div className="button-container">
          <button className="button is-primary" onClick={handleClose}>
            Roger That!
          </button>
        </div>
      </div>
    </>
  }


  if (verificationLoginId) {
    return <>
      <div className="double-padded content center-text" style={{maxWidth: 360}} onClick={() => sendMessage('focusLoginCode')}>
        <h2>📨 Email Sent</h2>
        <p>
          Type the 4-digit code we sent you:
        </p>
        <LoginCodeForm loginId={verificationLoginId} onComplete={handleVerification} noRedirect/>

        { !showEmailTips &&
          <p className="note">
            <a className="link" onClick={() => setShowEmailTips(true)}>
              { attempts <= 1 ?
                "Didn't get the email?"
              :
                "Still didn't get that email?"
              }
            </a>
          </p>
        }
        <div className={classNames("tips", {visible: showEmailTips})}>
          { attempts <= 1 ?
            <p className="note">
              If the email doesn&apos;t arrive in a few minutes, <b>look in
              your { email.toString().toLowerCase().endsWith('gmail.com') ?
                <a href="https://mail.google.com/#spam" target="_blank" rel="noreferrer" className="link">spam folder</a>
              :
                <span>spam folder</span>
              }</b> and double-check the spelling of your email address: <b>{email}</b> <a className="nowrap" onClick={handleTryAgain}>
                (wrong email?)
              </a>
            </p>
          :
            <p className="note">
              So sorry you&apos;re having trouble with the verification email.
              Tap the <b>Get Help</b> button and we&apos;ll get you sorted out.
            </p>
          }
          <div className="button-container">
            { required ?
              <>
                <button className={classNames("button", {'is-primary': attempts <= 1})} onClick={handleTryAgain}>Try Again</button>
                <a className={classNames("button", {'is-primary': attempts > 1})} href="https://socialjazz.com/contact.html" target="_blank" rel="noreferrer">
                  Get Help
                </a>
              </>
            :
              <button className="button" onClick={onClose}>Cancel Verification</button>
            }
          </div>
        </div>
      </div>

      <style jsx>{`
        .tips {
          max-height: 0;
          overflow: hidden;
          transition: max-height 300ms ease-out;
          padding-bottom: 0.2rem;
        }
        .tips.visible {
          max-height: 250px;
          overflow: hidden;
        }
      `}</style>
    </>
  }

  if (existingEmail) {
    return <>
      <div className="double-padded content center-text" style={{maxWidth: 360}}>
        <h2>That&apos;s Odd 🤔</h2>
        <p>
          It looks like there is already an account for {email}.
        </p>
        <div className="button-container">
          <button className="button is-primary" onClick={handleSwitchAccounts}>
            Log in with that email
          </button>
          { required ?
            <button className="button" onClick={handleTryAgain}>Go Back</button>
          :
            <button className="button" onClick={onClose}>Cancel</button>
          }
        </div>
      </div>
    </>
  }

  return <>
    <div className="content double-padded center-text main"  style={{maxWidth: 360}}>

      <h2>What&apos;s your email address?</h2>

      <form method="POST" onSubmit={handleSubmit}>
        <div className="field">
          <div className="control">
            <input
              className="input"
              type="email"
              name="email"
              placeholder="your-email@work.com"
              value={email}
              onInput={handleEmailInput}
              autoFocus
              autoComplete="username"
              disabled={busy}
              ref={emailInput}
            />
          </div>

          <div className="control" style={{margin: '1rem 2rem 1rem 1rem'}}>
            <Checkbox checked={wantsMarketingEmails} onChange={() => setWantsMarketingEmails(!wantsMarketingEmails)} disabled={busy}>
                <div className="nowrap">
                  Send me news and offers.
                </div>
            </Checkbox>
          </div>
        </div>

        {
          !!error && <p className="error-message">{error}</p>
        }

        <div className="center">
          <div className="button-container">
            <button className="full-width button is-primary" disabled={busy || !email.trim()}>
              { busy ?
                'Sending Email...'
              :
                'Send Verification Code'
              }
            </button>
            { !required &&
              <button type="button" className="button" onClick={onClose}>Cancel</button>
            }
          </div>

          <p className="note">
            By continuing you agree to <a href="https://socialjazz.com/terms.html" target="_blank" rel="noreferrer">the terms</a>.
          </p>
        </div>
      </form>

    </div>

    <style jsx>{`
      .main {
        position: relative;
      }
      .input {
        text-align: center;
      }
      .loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding-top: 2rem;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 1;
      }
    `}</style>
  </>


}
import { logout } from "@/src/session"
import { useAppRouter } from "@/src/useAppRouter"
import useCurrentUser from "@/src/useCurrentUser"
import { sendMessage } from "@/src/useMessage"


export default function BackstageFooter () {
  const router = useAppRouter()
  const isBackstage = router.asPath.startsWith('/backstage')
  if (!isBackstage) return null

  return <BackstageFooterUI/>
}


// Split the UI into a separate component to avoid calling useCurrentUser too soon
function BackstageFooterUI () {
  const router = useAppRouter()
  const user = useCurrentUser()
  if (!user) return null

  async function signout() {
    sendMessage('loading', true)
    await logout()
    router.push('/login')
    sendMessage('loading', false)
  }

  return <>
    <footer className='spread center-vertically nonprintable'>
      <p className='center-text subtle small indented'>
        You are signed in as {user.email}
      </p>
      <div className="button-container">
        <a href="#" className="button" onClick={signout}>
          Sign Out
        </a>
      </div>
    </footer>

    <style jsx>{`
      .button {
        font-size: 0.8rem;
      }
      footer {
        position: fixed;
        left: 24px;
        right: 24px;
        bottom: 0;
        background-color: #eee;
        border-radius: 8px 8px 0 0;
        padding: 0.5rem;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.4);
      }
    `}</style>
  </>
}
import useSWR, { mutate } from 'swr'
import rpc from './rpc'


export default function useRpc(method, args) {

  if (method.startsWith('/')) {
    throw Error(`Don't prefix method name with a forward slash on: useRpc("${method}")`)
  }

  const fetcher = async (key) => {
    const [method, args] = JSON.parse(key)
    return await rpc(method, args, {throwErrors: true, isFetch: true})
  }

  // If the UI gets an API error when fetching data, it should retry, but not
  // too many times (to avoid hammering the API on an unrecoverable error).
  const opts = {
    errorRetryCount: 1,
    errorRetryInterval: 5000, // 5 seconds between retries
  }

  // In development mode, as files are saved, the UI reloads and the servers
  // auto-restart. This is very useful, but sometimes the UI makes a request as
  // the dev servers are rebooting, causing an API error. In that case, the UI
  // should try again a few times, to avoid the need for a manual page reload.

  if (typeof window !== 'undefined' && window.location.host.includes('localhost')) {
    opts.errorRetryCount = 20
    opts.errorRetryInterval = 250
  }

  // NOTE: The first params (to useSWR) are passed to the fetcher, but those
  // need to be converted into a string. Otherwise SWR will NOT correctly detect
  // when the key matches a recent call, and the deduping will not work, causing
  // a flood of requests to the API (e.g. when the browser is resized).
  const key = JSON.stringify([method, args])

  const { data, error, mutate:reload } = useSWR(key, fetcher, opts)

  return [data, error, reload]
}


export async function refreshRpc (method) {
  if (method) {
    const key = JSON.stringify([method, null])
    await mutate(key)
  } else {
    console.warn('Calling refreshRpc without a method name is a noop')
  }
}

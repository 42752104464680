import useDeviceInfo from "@/src/useDeviceInfo"
import classNames from "classnames"
import { useState, useEffect, useRef } from "react"
import { createPortal } from "react-dom"


export default function Modal ({ visible, children, align, marginTop, onClickOutside, noBackground, noPortal, noScroll }) {

  const clickStartedOnWrapper = useRef(false)
  const prevHeight = useRef(0)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  const [height, setHeight] = useState(0)
  const {height:pageHeight, iphone, keyboardOnScreen} = useDeviceInfo()

  const maxHeight = `calc(${height ? (height - 16) + 'px' : '87vh'} - ${marginTop || '0px'})`

  //----------------------------------------------------------------------------
  // On iOS devices (i.e. iPhone), when a text input gets focus, Safari will:
  // - open the on-screen keyboard
  // - fire a resize event
  // - scroll the page put text input above keyboard (if hidden)
  //
  // In this case, resizing the modal is bad, because:
  // - the modal can end up being very short and hard to use
  // - changing height confuses scrolling and most of modal goes off screen
  //----------------------------------------------------------------------------
  useEffect(() => {
    if (!iphone) {
      setHeight(keyboardOnScreen ? prevHeight.current : pageHeight)
    }

    prevHeight.current = pageHeight
  }, [iphone, pageHeight, keyboardOnScreen])


  if (!visible) return null
  if (!mounted) return null

  function handleClickOnWrapper (e) {
    // Ignore any clicks where the mousedown event did not start on the wrapper
    // This prevents a "bug" when using a click-and-drag technique to select the
    // caption text (in the PostGroupEditor) and then releasing the mouse button
    // outside the modal. Without this check, the modal would close.
    if (!clickStartedOnWrapper.current) return

    if (e.currentTarget !== e.target) return
    if (onClickOutside) onClickOutside()
  }

  function portalWrapper (children, target) {
    if (noPortal) {
      return children
    } else {
      return createPortal(children, document.body)
    }
  }

  return portalWrapper(
    <>
      <div className="modal-container">
        <div className={classNames("modal-overlay", {hidden: noBackground})}></div>

        <div
          className={classNames("modal-wrapper", {top: align === 'top', noscroll: !!noScroll})}
          aria-modal={true}
          tabIndex={-1}
          role="dialog"
          style={{paddingTop: marginTop || 0}}
          onClick={handleClickOnWrapper}
          onMouseDown={() => clickStartedOnWrapper.current = true}
        >
          <div className="modal" onMouseDown={e => {
            e.stopPropagation() // Don't let wrapper get this mouse down event
            clickStartedOnWrapper.current = false
          }} >
            <div className="modal-body">
              {children}
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .modal-container {
          z-index: 1000;
          position: fixed;
          left: 0;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
        .modal-overlay {
          background-color: #000;
          left: 0;
          opacity: 0.7;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
          position: absolute;
        }
        .modal-overlay.hidden {
          opacity: 0;
        }
        .modal-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          outline: 0;
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 2;
        }

        {/*
          Push modals to the top of the screen on phones. Otherwise, iOS will
          leave empty space on bottom of Safari after keyboard is closed
        */}
        @media only screen and (max-width: 750px) {
          .modal-wrapper {
            align-items: flex-start;
          }
        }

        .top {
          align-items: flex-start;
        }

        .modal {
          background: white;
          border-radius: 0.25rem;
          display: block;
          margin: 0;
          max-height: ${maxHeight};
          margin: 0.5rem;
          overflow-y: auto;
          position: relative;
          box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
        }

        .noscroll .modal {
          overflow: hidden;
        }

        .modal-body {
          position: relative;
        }

      `}</style>
    </>,

    document.body,
  )
}

/* eslint @next/next/no-img-element: 0 */  // --> OFF

import 'bulma'
import '../styles/globals.css'
import { createPortal } from "react-dom"
import Head from 'next/head'
import { useEffect, useRef, useState } from 'react'
import { sendMessage, useMessage } from '@/src/useMessage'
import LoadingSpinner from '@/components/LoadingSpinner'
import Modal from '@/components/Modal'
import classNames from 'classnames'
import useDeviceInfo from '@/src/useDeviceInfo'
import useEnv from '@/src/useEnv'
import { useAppRouter } from '@/src/useAppRouter'
import { get } from '@/src/api'
import OrgForm from '@/components/OrgForm'
import VerifyEmailForm from '@/components/VerifyEmailForm'
import UserInterfacePointer from '@/components/UserInterfacePointer'
import BackstageFooter from '@/components/BackstageFooter'
import { identifyUser, trackPageView } from '@/src/appAnalytics'
import CloseIcon from '@/components/icons/CloseIcon'
import { loadSession, switchToOrg } from '@/src/session'
import { jazzlinkFrameFix, jazzlinkMode } from '@/src/misc'
import useCurrentOrg from '@/src/useCurrentOrg'


export default function App ({ Component, pageProps }) {

  const userInfo = useRef(null)

  const [error, setError] = useState(null)

  const [loading, setLoading] = useState(false)
  const [loadingSession, setLoadingSession] = useState(true)
  const appLoaded = !loading && !loadingSession

  const [highlightedElement, setHighlightedElement] = useState()
  const [signupMessage, setSignupMessage] = useState(false)
  const [reportedViewportHeight, setReportedViewportHeight] = useState(0)
  const [showingOrgForm, setShowingOrgForm] = useState(false)
  const [verifyingEmail, setVerifyingEmail] = useState(false)
  const [currentEmail, setCurrentEmail] = useState('')
  const [scheduleUrl, setScheduleUrl] = useState('')

  const currentOrg = useCurrentOrg()

  const env = useEnv()
  const router = useAppRouter()


  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])


  useEffect(() => {
    jazzlinkFrameFix()
  }, [])


  useEffect(() => {
    autoSwitchOrgs()

    async function autoSwitchOrgs () {
      const orgId = router.query.org

      if (!orgId) return
      if (!currentOrg) return

      // Make sure the user is using the correct org (based on the URL)
      const currentOrgId = currentOrg?.id || ''
      if (orgId.toString() !== currentOrgId.toString()) {
        await switchToOrg({id: orgId})

        // Drop the `org` param from the url
        let params = {...router.query}
        delete params.org
        router.replace({pathname: router.pathname, query: params})
      }

      setLoadingSession(false)
    }
  }, [router, currentOrg])

  useEffect(() => {
    load()
    async function load () {
      if (typeof window !== 'undefined') {
        // Redirect to https (if not running on localhost)
        const isLocalhost = window.location.hostname === 'localhost'
        const isSecure = window.location.protocol === 'https:'
        if (!isSecure && !isLocalhost) {
          const currentUrl = window.location.href
          const newUrl = currentUrl.replace('http:', 'https:')
          window.location.replace(newUrl)
          return
        }
      }

      const path = window.location.href.substring(window.location.origin.length)
      const queryParams = new URLSearchParams(window.location.search)
      const orgId = queryParams.get('org')

      // Redirect the user to a jazzlink page (if needed)
      if (orgId) {
        const jazzlinkUrl = await getJazzlinkUrl(orgId)
        if (jazzlinkUrl) {
          router.replace(jazzlinkUrl)
          return
        } else if (path.startsWith('/invite')) {
          // If this is an invite link (and no jazzlink redirect is needed),
          // then redirect to the login page to redeem the auth key.
          // console.log('redirecting to login', path.replace('/invite', '/login'))
          router.replace(path.replace('/invite', '/login'))
          return
        }
      }

      await loadSession()
      setLoadingSession(false)
    }

    async function getJazzlinkUrl(orgId) {
      // Don't redirect to jazzlink page, if already running in jazzlink mode
      if (jazzlinkMode()) return

      // Check to see if this org has a jazzlink url that should be used
      const res = await get(`/orgs/${orgId}/jazzlinkUrl`)
      let url = (await res.json()).url
      if (!url) return

      // Build a (starting) path to pass to the jazzlink page
      let path = window.location.pathname
      if (path[0] !== '/') path = '/' + path // Ensure path starts with a slash
      path += window.location.search

      // Put the path in the hash, so that jazzlink can open it when it launches
      const encodedPath = encodeURIComponent(path)
      url += `#jazzpath=${encodedPath}`

      return url
    }
  }, [])

  useEffect(() => setLoading(false), [router.pathname])

  useEffect(() => {
    document.body.addEventListener('scroll', stopScroll)
    return () => document.body.removeEventListener('scroll', stopScroll)

    function stopScroll (e) {
      e.preventDefault()
    }
  }, [])


  useEffect(() => {
    window.sendMessage = sendMessage
    if("serviceWorker" in navigator) {
      window.addEventListener("load", function () {
       navigator.serviceWorker.register("/sw.js").then(
          function (registration) {
            console.log("Service Worker registration successful with scope: ", registration.scope)
          },
          function (err) {
            console.log("Service Worker registration failed: ", err)
          }
        )
      })
    }
  }, [])


  // Each time the app loads, update the user identity in the analytics services
  useEffect(() => {
    try {
      identify()
    } catch(err) {
      console.warn('Could not identify user')
    }

    async function identify () {
      try {
        const res = await get(`/auth/isLoggedIn`)
        const login = await res.json()

        const {user} = login
        if (!user) return

        userInfo.current = user

        identifyUser({email: user.email, userId: user.id})
      } catch (err) {
        console.warn(`Failed to identify user ${err}`)
      }
    }
  }, [])


  // Send (client-side) page view events to the analytics platforms
  useEffect(() => {
    router.events.on('routeChangeComplete', trackPageView)
    return () => { router.events.off('routeChangeComplete', trackPageView) }
  }, [router])


  // TODO: Show some UI if there are no orgs
  // TODO: Show UI for picking an org, if there are several

  useMessage('showPostSignupMessage', () => setSignupMessage(true) )
  useMessage('error', setError )
  useMessage('loading', setLoading )
  useMessage('login', () => { router.push('/login') })
  useMessage('highlightElement', el => setHighlightedElement(el))
  useMessage('editOrg', () => setShowingOrgForm(true))
  useMessage('scheduleOnboardingCall', () => {
    let url = "https://calendly.com/socialjazzapp/onboarding-call"
    if (userInfo.current) {
      // Pass in name and email to help fill out the Calendly form a bit faster
      const queryParams = new Map()

      queryParams.set('hide_gdpr_banner', '1')

      if (userInfo.current.email) queryParams.set('email', userInfo.current.email)
      const name = [userInfo.current.firstName, userInfo.current.lastName].filter(v => v).join(' ')
      if (name) queryParams.set('name', name)
      if (queryParams.size > 0) {
        url += '?' + new URLSearchParams(queryParams).toString()
      }
      console.log('url', url)
    }
    setScheduleUrl(url)
  })


  const { mobile, iphone } = useDeviceInfo()
  const backstage = router.pathname.startsWith('/backstage')

  useMessage('verifyEmail', email => {
    setVerifyingEmail(true)
    setCurrentEmail(email || '')
  })

  function closeEmailVerification () {
    setVerifyingEmail(false)
  }

  const loaded = (
    typeof mobile !== 'undefined' &&
    Object.keys(env).length > 0
  )

  // ===========================================================================
  // TLDR: Mobile browsers often lie about the viewport height, so...
  // - Don't trust `window.innerHeight()`
  // - Avoid `VH` units (in CSS) whenever possible
  // - Never go over `85VH` or iOS will cut off some of the content
  // - Get VISIBLE  viewport from a `fixed` div with top: 0 and bottom: 0
  // - Get REPORTED viewport from a `fixed` div with top: 0 and height: 100vh
  // ---------------------------------------------------------------------------
  //
  // Several mobile browsers change the size of the viewport by collapsing and
  // expanding parts of the UI (a.k.a. "browser chrome") when the page is
  // scrolled, for the purpose of maximizing the space used to show the webpage.
  //
  // This browser behavior causes changes to the size of the VISIBLE viewport
  // (the amount of space displaying the webpage content), but some browsers
  // intentionally give a static viewport, regardless. This REPORTED viewport
  // size is the larger one (as if the browser UI was collapsed). As a result,
  // before the browser UI collapses (the initial state when the page loads),
  // some of the REPORTED viewport area will not be visible (off-screen or
  // hidden behind browser UI). As a nasty side effect, this makes the CSS `vh`
  // units unreliable! https://stackoverflow.com/q/37112218
  //
  // The exact rules for when (and if) the browser changes the VISIBLE viewport
  // size vary based on: brower engine, browser version, user scrolling
  // behavior, and the size of the webpage...
  //
  // For example: Safari on the iPhone (iOS 15) will only expand the VISIBLE
  // viewport when the user scrolls the page up, and only if the page height is
  // at least one pixel greater than the REPORTED viewport size. When scrolling
  // down, Safari will expand its UI again (reducing the VISIBLE viewport), but
  // only if the page height is at least 82 pixels taller than the REPORTED
  // viewport height. (Why 82 pixels? I can't think of a good reason, although
  // 82 pixels happens to be the amount that the VISIBLE viewport height changes
  // when scrolling (on the iPhone).
  //
  // For Chrome (on Android) the logic is much more mysterious. It seems to
  //
  // Side Note: Scrolling initiated by Javascript, with `scrollTo`, will not
  // trigger the collapse of the browser UI. The scrolling must be user driven.
  //
  // To go further down the rabbit hole, read this...
  // https://github.com/bokand/bokand.github.io/blob/master/web_viewports_explainer.md
  //
  // ===========================================================================
  useEffect(() => {
    setReportedViewportHeight(
      document.getElementById('reported-viewport-height').getBoundingClientRect().height
    )
  }, [])

  const isAppPage = (
    !router.asPath.startsWith('/backstage') &&
    !router.asPath.startsWith('/invoices')
  )

  // Note: tabIndex="-1" is used to disable Chrome's "tap to search" on mobile.
  // See https://developers.google.com/web/updates/2015/10/tap-to-search

  return <>
    <Head>
      <title>Social Jazz</title>
      {/* Avoid the 300ms click delay on mobile devices */}
      <meta name="viewport" content="width=device-width"/>

      { backstage && <meta name="robots" content="noindex" />}

      {/* Prevent auto-zoom when input gets focus on iOS */}
      { backstage && <meta name="viewport" content="width=device-width initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />}

      {/* Tell browsers not to offer "translate this page" prompts */}
      <meta charSet="UTF-8"/>
      <meta name="google" content="notranslate"/>
      <meta httpEquiv="Content-Language" content="en"/>

      {/* Allow install-to-homescreen on mobile devices */}
      <link rel="manifest" href="/app.manifest" />
    </Head>

    {/* Used to get height equal to 100vh on mobile browser (see not above) */}
    <div id='reported-viewport-height'></div>

    {/* Make Safari hide the URL bar when the users scrolls on iPhone */}
    { (iphone && isAppPage) &&
      <div className='iphone-scroll-hack' style={{height: reportedViewportHeight + 1}} />
    }

    <div tabIndex="-1" className={classNames({'app-shell': isAppPage, 'scrollable-shell': !isAppPage})}>
      <Modal visible={error}>
        <div className="content" style={{padding: '2rem', margin: 'auto', maxWidth: 400}}>
          <h1>
            😬 Uh oh...
          </h1>
          <pre style={{color: 'darkred', whiteSpace: 'normal'}}>
            {error}
          </pre>
          <div className='button-container'>
            <a className='button is-primary' href="https://socialjazz.com/contact.html" target="_blank" rel="noreferrer">
              Contact Support
            </a>
            <button className="button" onClick={() => setError(null)}>
              Dismiss
            </button>
          </div>
        </div>
      </Modal>

      { verifyingEmail &&
        <Modal visible={true} onClickOutside={() => sendMessage('focusLoginCode')}>
          <VerifyEmailForm onClose={closeEmailVerification} defaultEmail={currentEmail}/>
        </Modal>
      }

      { showingOrgForm &&
        <Modal visible={true}>
          <div className="modal-form content">
            <h2>Your Company Info</h2>
            <OrgForm onClose={() => setShowingOrgForm(false)}/>
          </div>
        </Modal>
      }

      { highlightedElement &&
        <UserInterfacePointer
          onClose={() => setHighlightedElement(null)}
          element={highlightedElement}
        />
      }

      <Modal visible={signupMessage} onClickOutside={() => setSignupMessage(false)}>
        <div className="content" style={{padding: '1rem 1.5rem', margin: 'auto', maxWidth: 380}}>
          <button className='button is-inverted no-border absolute top right mt-3 bg-none' onClick={() => setSignupMessage(false)}>
            <CloseIcon/>
          </button>

          <div className='flex-row spread'>
            <div className='bold text-2xl mb-2'>
              Welcome to Social Jazz!!!
            </div>
          </div>

          <p className='mt-1 mb-4'>You just created a whole year of engaging and creative social media posts.</p>
          <p className='mt-1 mb-4'>To help you make the most of Social Jazz, we offer a free onboarding call where our team will walk you through the features and answer any questions you may have.</p>

          <div className="pt-2 flex-col sm_flex-row gap-2 flex-justify-center">
            <button className='button is-purple' onClick={() => {
              setSignupMessage(false)
              sendMessage('scheduleOnboardingCall')}
            }>
              Schedule Onboarding Call
            </button>
            <button className="button is-primary bold" onClick={() => setSignupMessage(false)}>
              Not Now
            </button>
          </div>
        </div>
      </Modal>

      <Modal visible={!!scheduleUrl} noScroll onClickOutside={() => setScheduleUrl('')}>
        <div style={{width: 1000, height: 800, maxWidth: '95vw', maxHeight: '90vh', position: 'relative'}}>
          <iframe src={scheduleUrl} style={{width: '100%', height: '100%'}}>
          </iframe>
        </div>
      </Modal>

      <div className="env">
        { env.IS_STAGING ?
          <div className="staging">Staging</div>
        : env.IS_DEVELOPMENT ?
          <div className="dev">Development</div>
        :
          null
        }
      </div>

      <div className={classNames("layout", {backstage})}>
        <div className="main">
          { loaded && <Component {...pageProps} /> }
        </div>
      </div>
    </div>

    <BackstageFooter/>

    { mounted && createPortal(<>
        <div className={classNames('loading', {visible: !appLoaded})}>
          <div className="loading-background"></div>
          <div className="loading-spinner">
            <LoadingSpinner size="medium"/>
          </div>
        </div>
      </>, document.body)
    }

    <style jsx>{`
      .modal-form {
        padding: 2rem;
        max-width: 30rem;
      }
      .modal-form h2 {
        text-align: center;
      }
      .scroller-container {
        position: absolute;
        z-index: 10000;
        top: 0;
        left: 0;
        right: 0;
      }
      .scroller {
        width: 100%;
        background-color: #a3fc07cc;
        height: 2000px;
        margin-bottom: 300px;
        font-size: 1.2rem;
        text-align: right;
        padding: 2rem;
        font-weight: bold;
      }

      #reported-viewport-height {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        pointer-events: none;
        z-index: -1;
      }

      .app-shell .layout, .app-shell .main {
        touch-action: none; /* iOS */
        -webkit-overflow-scrolling: none;
        overflow: hidden;
        overscroll-behavior: none;
      }
      .iphone-scroll-hack {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
      }
      .app-shell {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .scrollable-shell {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
      }
      .env {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10000;
        text-align: center;
        pointer-events: none;
        font-size: 0.75rem;
        font-weight: 600;
        margin-top: -2px;
      }
      .env > div {
        display: inline-block;
        margin: auto;
        padding: 0 0.3rem;
        border-radius: 2px;
        box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2);
      }
      .env .staging {
        color: #a74040;
        background: #ffdcdc;
      }
      .env .dev {
        color: #a36c07;
        background: #ffe1b5;
      }
      .backstage {
        max-width: 1280px;
        margin: 0 auto;
      }
      .backstage :global(.dashboard){
        padding-bottom: 4rem;
      }
      .modal-buttons {
        text-align: center;
      }
      .modal-buttons button {
        margin: 0.3rem 0.2rem;
        min-width: 11rem;
      }
      .loading {
        position: fixed;
        pointer-events: none;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 99999;
        opacity: 0;
        transition: opacity 600ms 250ms;
      }
      .loading-spinner {
        position: absolute;
        width: 100%;
        height: 100%;
        padding-top: 30%;
        padding-top: 30vh;
      }
      .loading-background {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        opacity: 0.7;
      }
      .loading.visible {
        pointer-events: auto;
        opacity: 1;
      }
    `}</style>
  </>
}

//******************************************************************************
// NOTE: This script is small wrapper around the analytics libraries we use.
//******************************************************************************

//==============================================================================
// Public Functions - used by external scripts (e.g. by the Social Jazz app)
//==============================================================================


export function trackEvent (eventName, data) {
  afterAnalyticsLoad(() => {
    mixpanel.track(eventName, data)
    posthog.capture(eventName, data)
  })
}


export function trackConversion () {
  afterAnalyticsLoad(() => {
    mixpanel.track('conversion')
    if (typeof gtag !== 'undefined') {
      gtag('event', 'conversion', {'send_to': 'AW-358305248/cLCACOfXy_gCEOCb7aoB'})
    }
  })
}


export function identifyUser (data) {
  if (!data) return

  afterAnalyticsLoad(() => {
    if (data.userId) {
      posthog.identify(data.userId)
      mixpanel.identify(data.userId)
    }

    if (data.email) {
      posthog.people.set({ email: data.email })
      mixpanel.people.set({ $email: data.email })
    }
  })
}


export function trackPageView () {
  // Grab the location info right away, in case the user navigates away quickly
  const path = window.location.pathname
  const href = window.location.href

  // Short delay in hopes that the page title will finally be set (by Next.js)
  setTimeout(() => {
    trackEvent('page_view', {
      page_title:    document.title,
      page_path:     path,
      page_location: href,
      event_source:  'appAnalytics',
    }, 1000)
  })
}


//==============================================================================
// End of Public Functions
//==============================================================================

let callBuffer = []
let scriptLoaded = false


function afterAnalyticsLoad (callback) {
  if (scriptLoaded) {
    callback()
  } else {
    callBuffer.push(callback)
    waitForLoad()
  }
}


function flushBuffer () {
  while (callBuffer.length) {
    const fn = callBuffer.shift()
    fn()
  }
}


let pollingStarted = false

function waitForLoad () {
  if (pollingStarted) return
  pollingStarted = true

  var attempts = 30 // seconds max waiting for the analytics.js to load
  checkForAnalytics()

  function checkForAnalytics () {
    if (typeof window === 'undefined') return

    attempts -= 1

    const dependencies = [ 'mixpanel', 'posthog' ]
    const analyticsLoaded = dependencies.every(dep => window[dep])

    if (analyticsLoaded) {
      scriptLoaded = true
      flushBuffer()
    } else if (attempts <= 0) {
      console.warn('Analytics never loaded')
    } else {
      setTimeout(checkForAnalytics, 1000)
    }

  }
}
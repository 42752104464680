import { useEffect } from "react"


//==============================================================================
// NOTE: The callback should be wrapped in a `useCallback` hook, so that it
// doesn't change on every render.  Otherwise, you can end up in a situation
// where the callback doesn't fire correctly.
//==============================================================================


export function useMessage (message, callback) {
  useEffect(() => {
    const handler = (e) => callback(e.detail)
    addListener(message, handler)
    return () => {
      removeListener(message, handler)
    }
  }, [message, callback])
}


export function sendMessage (message, data) {
  document.dispatchEvent(new CustomEvent(message, { detail: data }))
}

function addListener(message, fn) {
  document.addEventListener(message, fn)
}


function removeListener(message, fn) {
  document.removeEventListener(message, fn)
}

import { IS_DEVELOPMENT } from '../../env'
import {post} from './api'
import { sleep } from './misc'
import { sendMessage } from './useMessage'


export default async function rpc (method, args, opts) {

  opts = opts || {}

  if (!method.includes('.')) {
    throw Error(`Invalid RPC method name(${JSON.stringify(method)}) -- should conatain a dot`)
  }

  const url = `/ray/${method}`
  const res = await post(url, args || {})
  let json = null
  try {
    json = await res.clone().json()
  } catch (err) {
    console.warn(`Failed to parse JSON on response from ${url}`)

    const devReloadError = (
      opts.isFetch &&
      typeof window !== 'undefined' &&
      IS_DEVELOPMENT &&
      err.message.startsWith('Unexpected end of JSON')
    )

    // Suppress errors in dev mode when API fails due to hot reloading
    if (devReloadError) {
     return await retryDevRequest()
    } else {
      throw err
    }
  }

  if (json.ok) {
    return json.result
  } else {
    let errorMessage = json.userMessage || json.error || await res.clone().text() || ''
    if (errorMessage.match(/Unexpected token.* in JSON/)) {
      errorMessage = 'Response was not JSON'
    }
    if (errorMessage === 'Unauthorized') {
      console.log('----> RPC IS to BLAME for login redirect!!')
      // sendMessage('login')
      console.warn('Unauthorized error when calling RPC method: ' + method)
      console.log('RPC was about to send you to the login screen!!!')
    } else if (IS_DEVELOPMENT && (await res.clone().text()).includes('ECONNREFUSED')) {
      // In dev mode, servers restart after any file changes, caused disconnects
      return await retryDevRequest()
    } else {
      console.warn(`Error when calling ${method}`)
      console.log(await res.clone().text())

      console.error(errorMessage)
      errorMessage = errorMessage.split(' | ').join('\n')
      if (opts.throwErrors) {
        const wrappedError = new Error(errorMessage)
        if (json.error === 'UserFacingError' ) wrappedError.type = 'UserFacingError'
        throw wrappedError
      } else {
        sendMessage('error', errorMessage)
      }
    }
  }

  async function retryDevRequest () {
    console.warn('Dev Load Error - Retrying')
    await sleep(100)
    return await rpc (method, args, opts)
  }
}


//------------------------------------------------------------------------------
// This calls an RPC endpoint that returns an asynchronous background task. The
// task has `done` and `result` methods -- both wait for the task to finish, but
// `result` also returns the result of the task (by default, the task target).
// If you want progress updates, you can pass a callback to either `done` or
// `result`, and it will be called with the percent complete (an integer 0-100).
//
// Sample usage:
//
//      const task = await rpcTask('some.apiMethod', {foo: 'bar'})
//      await task.done(percent => console.log(percent + '% complete'))
//
//      // ...or use `task.result()`...
//
//      const task = await rpcTask('some.apiMethod', {foo: 'bar'})
//      const thing = await task.result()
//
//------------------------------------------------------------------------------
export async function rpcTask (method, args) {

  let task = await rpc(method, args, {throwErrors: true})

  if (!task || !task.id || !task.queuedAt) {
    throw Error(`Invalid response from server when calling ${method}, expected a task, but got ${JSON.stringify(task)}`)
  }

  let taskId = task.id

  // Time (in ms) between polling the server for task status
  const delay = 1000

  task.done = progressCallback => {
    // Keep track of failures (to fetch the task status), and retry a few times
    let retries = 0

    return new Promise((resolve, reject) => {
      setTimeout(checkProgress, delay)

      async function checkProgress () {

        try {
          let currentTask = null

          try {
            currentTask = await rpc('backgroundTasks.find', {id: taskId}, {throwErrors: true})
            retries = 0
          } catch (errFetchingTask) {
            console.error(errFetchingTask)
            retries += 1
            console.warn('RETRYING - ' + errFetchingTask.toString())
            if (retries <= 3) {
              setTimeout(checkProgress, delay)
            } else {
              reject(errFetchingTask)
            }
            return
          }

          if (typeof progressCallback === 'function') {
            await progressCallback(currentTask.percentComplete)
          }

          if (currentTask.completedAt) return resolve(currentTask)

          if (currentTask.failedAt) {
            const err = new Error(currentTask.errorMessage)
            err.stack = currentTask.errorBacktrace
            return reject(err)
          }

          if (currentTask.canceledAt) {
            if (currentTask.nextAttemptId) {
              taskId = currentTask.nextAttemptId
            } else {
              return reject('Task canceled')
            }
          }

          setTimeout(checkProgress, delay)
        } catch (err) {
          reject(err)
        }
      }
    })
  }


  task.result = async (progressCallback) => {
    await task.done(progressCallback)
    return await rpc('backgroundTasks.result', {id: taskId}, {throwErrors: true})
  }


  return task
}

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const SENTRY_ENVIRONMENT = (
  typeof location === 'undefined'                       ? 'non-browser'
  : location.hostname === 'app.socialjazz.app'          ? 'staging'
  : location.hostname === 'app.socialjazz.com'          ? 'production'
  : location.hostname === 'localhost'                   ? 'development'
  :                                                       'unknown'
)

const SENTRY_SAMPLE_RATE = parseFloat(process.env.SENTRY_SAMPLE_RATE || '0.1')

Sentry.init({
  dsn: SENTRY_DSN,

  environment: SENTRY_ENVIRONMENT,

  tracesSampler: samplingContext => {
    switch (SENTRY_ENVIRONMENT) {
      case 'production':  return SENTRY_SAMPLE_RATE
      case 'staging':     return SENTRY_SAMPLE_RATE
      default:            return 0
    }
  }

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})

import classNames from "classnames"
import { useEffect, useState } from "react"


export default function LoadingSpinner ({size = 'small', padded = false}) {

  const [visible, setVisible] = useState(false)
  useEffect(() => {
    // Delay to allow the initial render to finish, so CSS fade in can fire
    const timer = setTimeout(() => setVisible(true), 250)
    return () => clearTimeout(timer)
  }, [])

  let width = 792
  let height = 576

  if (size === 'large') {
    width /= 2
    height /= 2
  }
  if (size === 'medium') {
    width /= 3
    height /= 3
  }
  if (size === 'normal') { // TODO: Make this the default size
    width /= 4
    height /= 4
  }
  if (size === 'small') {
    width /= 6
    height /= 6
  }
  if (size === 'tiny') {
    width /= 8
    height /= 8
  }


  return <>
    <div className={classNames('spinner-container', {visible, padded})}>
      <div className="spinner" style={{width, height}}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 792 576">
          <g>
            <polygon className="purple-bubble" points="552.6,225.6 384.7,256.3 332.9,265.9 362,431.9 474.7,406 500.9,406 522.9,421.6 522.9,406 556.5,406 "/>
            <polygon className="blue-bubble" points="457,223.3 447,169.3 243.8,148.1 281.4,345.8 265.6,379.6 286.3,371.6 291.7,400 321.4,390.8 338.2,385.4 428.7,357.1 476.8,341 	"/>
            <polygon className="green-overlap" points="332.9,265.9 352.7,380.9 427.6,357.4 476.8,341 460.2,242.5 384.7,256.3 	"/>
            <g>
              <path className="dot-1" d="M363.7,318.8v-19.2H383v19.2H363.7z"/>
              <path className="dot-2" d="M399.9,318.8v-19.2h19.2v19.2H399.9z"/>
              <path className="dot-3" d="M436.1,318.8v-19.2h19.2v19.2H436.1z"/>
            </g>
          </g>
        </svg>
      </div>
    </div>

    <style jsx>{`
      .spinner-container {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 700ms 300ms;
      }
      .visible {
        opacity: 1;
      }
      .padded {
        margin: 2rem;
      }
      .spinner {
        display: inline-block;
        margin-top: -16px;
        margin-bottom: -16px;
      }
      .purple-bubble{fill:#666699;}
      .blue-bubble{fill:#3499CC;}
      .green-overlap{fill:#6FBE44;}

      .dot-1, .dot-2, .dot-3 {
        animation: dash 300ms infinite alternate;
      }

      .dot-3 {
        animation-delay: 0ms;
        fill: #ffffff;
      }

      .dot-2 {
        animation-delay: 500ms;
        fill: #ffffff;
      }

      .dot-1 {
        animation-delay: 1000ms;
        fill: #ffffff;
      }

      @keyframes dash {
        0% {
          fill-opacity: 0;
        }
        100% {
          fill-opacity: 1;
        }
      }

    `}</style>
  </>
}
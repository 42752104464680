export function addRemove(arr, val) {
  if (arr.includes(val)) {
    return arr.filter(e => e !== val)
  } else {
    return arr.concat(val)
  }
}


//------------------------------------------------------------------------------
// A sane alternative to Array.sort:
//  - doesn't convert everything into strings
//  - empty values sort to the front (undefined, null, '')
//  - numbers are sorted numerically (2 < 10), instead of alphanumerically
//  - case insensitive sorting of strings
//------------------------------------------------------------------------------
export function sort(arr) {
  // Need to wrap the values in an object, otherwise undefined values will get
  // automatically sorted to the end (by V8) without ever calling our sort code.
  const wrapped = arr.map(value => ({value}))
  const sorted = sortBy(wrapped, 'value')
  return sorted.map(v => v.value)
}


export function sortBy(arr, callback) {
  let reversed = []
  if (typeof callback === 'string') {
    callback = [callback]
  }
  if (Array.isArray(callback)) {
    const rawKeys = callback
    // Check if any of the keys are using a leading dash for reversing the order
    reversed = rawKeys.map(k => k.startsWith('-'))
    // Remove leading dashes (used for reversing the order)
    const keys = rawKeys.map(k => k.substring( k.startsWith('-') ? 1 : 0 ))
    callback = o => keys.map(k => o[k])
  }

  return arr.sort((a, b) => {
    let arr1 = callback(a)
    let arr2 = callback(b)

    if (!Array.isArray(arr1)) arr1 = [arr1]
    if (!Array.isArray(arr2)) arr2 = [arr2]

    for (let i = 0; i < arr1.length; i++) {
      let v1 = arr1[i]
      let v2 = arr2[i]

      if (typeof v1 === 'undefined' || v1 === null) v1 = ''
      if (typeof v2 === 'undefined' || v2 === null) v2 = ''

      if (typeof v1 === 'string') v1 = v1.toLowerCase()
      if (typeof v2 === 'string') v2 = v2.toLowerCase()

      const direction = reversed[i] ? -1 : 1

      if (v1 < v2) return -1 * direction
      if (v1 > v2) return 1 * direction
    }

    return 0
  })
}


export function chunk(arr, size) {
  size = size || 1
  if (size < 1) throw Error("Invalid chunk size: " + size)

  const chunks = []
  for (var i = 0; i < arr.length; i += size) {
    chunks.push(arr.slice(i, i + size))
  }
  return chunks
}


export function count(arr, val) {
  let counter = 0
  arr.forEach(e => {
    if (e === val) counter += 1
  })
  return counter
}


export function flatten(arr) {
  let flat = []
  arr.forEach(e => {
    if (Array.isArray(e)) {
      flat = flat.concat(e)
    } else {
      flat.push(e)
    }
  })
  return flat
}


export function intersection(arr1, arr2) {
  let set1 = new Set(arr1)
  let set2 = new Set(arr2)

  let both = new Set()
  for (let elem of set1) {
    if (set2.has(elem)) {
      both.add(elem)
    }
  }
  return Array.from(both)
}


export function nonIntersecting(arr1, arr2) {
  let set1 = new Set(arr1)
  let set2 = new Set(arr2)

  let onlyInSet1 = new Set()
  for (let elem of set1) {
    if (!set2.has(elem)) {
      onlyInSet1.add(elem)
    }
  }
  return Array.from(onlyInSet1)
}


// Shorthand for creating filter function that checks any number of keys
//    filter(array, {id: 1, name: 'dave'})
// is the same as...
//    array.filter(e => e.id === 1 && e.name === 'dave')
export function filter(arr, conditions = {}) {
  return filterFn(arr, conditions, true)
}


export function filterOut(arr, conditions) {
  return filterFn(arr, conditions, false)
}


function filterFn(arr, conditions = {}, accept) {
  return arr.filter(obj => {
    for (const k in conditions) {
      const actual = obj[k]
      const expected = conditions[k]

      const isPrimitive = (
        expected === null ||
        typeof expected === 'undefined' ||
        typeof expected === 'string' ||
        typeof expected === 'boolean' ||
        typeof expected === 'number'
      )

      if (Array.isArray(expected)) {
        if (!expected.includes(actual)) return !accept
      } else if (isPrimitive) {
        if (actual !== expected) return !accept
      } else {
        throw Error(`Cannot filter using ${JSON.stringify(expected)} on ${JSON.stringify(obj)}`)
      }
    }
    return accept
  })
}


export function first(arr) {
  return arr[0]
}


export function last(arr) {
  return arr[arr.length - 1]
}


export function random(arr) {
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}


export function max(arr) {
  let biggest = undefined
  for (const item of arr) {
    if (item > biggest || typeof biggest === 'undefined') {
      biggest = item
    }
  }
  return biggest
}


export function sum(arr, callback) {
  if (typeof callback === 'string') {
    const key = callback
    callback = o => o[key]
  } else if (!callback) {
    callback = o => o
  }
  let total = 0
  for (const item of arr) {
    total += callback(item)
  }
  return total
}


export function toSentence (arr, joiner='and') {
  return [...arr.slice(0, -2), arr.slice(-2).join(` ${joiner} `)].join(', ')
}


export function uniq(arr) {
  return [...new Set(arr)]
}


export function uniqBy(arr, field) {
  const map = new Map()

  for (const item of arr) {
    map.set(item[field], item)
  }
  return Array.from(map.values())
}

/*==============================================================================
WARNING: Do not include any sensitive values (e.g. passwords, tokens, or secret
keys) in this file, because it will be bundled into the client-side app.
==============================================================================*/

export const NODE_ENV = process.env.NODE_ENV || 'development'

export const IS_PRODUCTION  = NODE_ENV === 'production'
export const IS_DEVELOPMENT = NODE_ENV === 'development'
export const IS_TESTING     = NODE_ENV === 'test'

export const FACEBOOK_APP_REVIEW_EMAIL = 'open_tybxdcj_user@tfbnw.net'

export const IS_STAGING = process.env.IS_STAGING === 'true'

export const SUPPRESS_LOGGING = process.env.SUPPRESS_LOGGING === 'true'
export const VERBOSE_LOGGING = process.env.VERBOSE_LOGGING === 'true'

export const CROSS_API_KEY = process.env.CROSS_API_KEY || '051968fd-1843-4902-91fd-811fd1ee02da'

export const POSTMARK_API_TOKEN = process.env.POSTMARK_API_TOKEN || '9bcd317a-318d-415c-8e89-5d32c03e97b3'

export const HOST = IS_PRODUCTION ? '0.0.0.0' : 'localhost'

export const API_URL = process.env.API_URL || 'http://localhost:5299'
export const APP_URL = process.env.APP_URL || 'http://localhost:3000'
export const CDN_URL = process.env.CDN_URL || 'http://localhost:3453'
export const PAY_URL = process.env.PAY_URL || 'http://localhost:4242'
export const RAY_URL = process.env.RAY_URL || 'http://localhost:7245'
export const UTM_URL = process.env.UTM_URL || 'http://localhost:8725'

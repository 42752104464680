import { sleep } from "./misc"
import { getSessionHeaders } from "./session"

const LOCAL_DEV = typeof window !== 'undefined' && window.location.host.includes('localhost')


export async function get (url) {

  let res = await apiFetch(url, {
    method: 'GET',
  })

  //----------------------------------------------------------------------------
  // In development mode, as files are saved, the UI will auto-reload and the
  // servers will auto-restart. Sometimes this results in API errors, because
  // the UI restarts before the servers finish rebooting. In that case, the API
  // request should try again, to avoid the need for a manual page reload.
  //----------------------------------------------------------------------------
  if (LOCAL_DEV && res.status === 500) {
    const errorRetryCount = 20
    const errorRetryInterval = 250

    for (let i  = 0; i < errorRetryCount; i++) {
      try {
        // Make sure that the error was from a dev reboot and not a real error
        const json = await res.clone().json()
        if (!json.error?.includes('ECONNREFUSED')) break

        await sleep(errorRetryInterval)
        console.log('Auto-retrying API GET', url)

        res = await apiFetch(url, {
          method: 'GET',
        })
      } catch (err) {
        console.log('Error with retry on API GET', url)
        console.error(err)
        break
      }
    }
  }

  return res
}


export async function patch (url, data) {
  if (!data) throw Error('Called api.patch() without any data')

  const res = await apiFetch(url, {
    method: 'PATCH',
    body: JSON.stringify(data)
  })
  return res
}


export async function post (url, data) {
  if (!data) throw Error('Called api.post() without any data')

  const res = await apiFetch(url, {
    method: 'POST',
    body: JSON.stringify(data)
  })
  return res
}


export async function del (url) {
  const res = await apiFetch(url, {
    method: 'DELETE',
  })
  return res
}


export async function apiFunction (path, args) {
  if (!path.startsWith('/functions/')) {
    throw Error(`URL passed to apiFunction() must start with "/functions/" - got ${path}`)
  }

  const res = await post(path, args)

  let errorMessage = ''
  try {
    if (res.status >= 400) {
      errorMessage = await res.text()
      throw Error('Server error')
    }

    const body = await res.text()
    if (!body) throw Error('BODY IS EMPTY :P')
    return JSON.parse(body)
  } catch (err) {
    console.log('Error: ' + err.toString())
    errorMessage = errorMessage || err.toString()
    throw Error(`API RPC call failed - ${errorMessage}`)
  }
}


export async function apiTask (path, args) {
  let task = await apiFunction(path, args)
  let taskId = task.id

  const delay = 1000

  task.done = callback => {
    let retries = 0

    return new Promise((resolve, reject) => {
      setTimeout(checkProgress, delay)

      async function checkProgress () {
        try {
          const currentTask = await apiFunction('/functions/backgroundTasks/find', {id: taskId})
          retries = 0

          if (typeof callback === 'function') {
            await callback(currentTask.percentComplete)
          }

          if (currentTask.completedAt) return resolve()
          if (currentTask.failedAt) {
            const err = new Error(currentTask.errorMessage)
            err.stack = currentTask.errorBacktrace
            return reject(err)
          }
          if (currentTask.canceledAt) {
            if (currentTask.nextAttemptId) {
              taskId = currentTask.nextAttemptId
            } else {
              return reject('Task canceled')
            }
          }

          setTimeout(checkProgress, delay)
        } catch (err) {
          console.error(err)
          retries += 1
          console.warn('RETRYING - ' + err.toString())
          if (retries <= 3) {
            setTimeout(checkProgress, delay)
          } else {
            return reject(err)
          }
        }
      }
    })
  }

  return task
}


async function apiFetch(url, options) {
  assertValidUrl(url)

  // Adjust the url to hit the /api/[...path].js route of this Next.js app
  url = '/api' + url

  return await fetch(url, {
    ...options,
    headers: getSessionHeaders(),
  })
}


function assertValidUrl(url) {
  if (url.indexOf('/api/') === 0 && !url.includes('.')) {
    throw Error(`Invalid API route: '${url}' -- do NOT prefix routes with /api`)
  }

  if (url.indexOf('/') !== 0) {
    throw Error(`Invalid API route: '${url}' -- should start with /`)
  }
}

import levenshtein from 'fast-levenshtein'
import { max } from './array.js'
import twitter from 'twitter-text'

const FIRST_LETTER = /\w\S*/g
const FIRST_LETTER_OF_CAMEL = /[A-Z]/g
const FIRST_LETTER_OF_SNAKE = /_./g
const VALID_EMAIL = /^\S+@\S+\.\S+$/


// TODO: Rename to this function to something like `currency` or `toCurrency`
//       to avoid confusion with jQuery's use of the `$` function.
export function $ (priceInPennies, opts) {
  if (typeof priceInPennies === 'undefined') {
    console.warn('Converting undefined price into zero')
    priceInPennies = 0
  } else if (!Number.isInteger(priceInPennies)) {
    throw Error('Expected price in pennies (as integer), but got ' + JSON.stringify(priceInPennies))
  }

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  const price = formatter.format(priceInPennies / 100)
  const showPennies = opts && opts.showPennies
  if (price.endsWith('.00') && !showPennies) return price.substring(0, price.length -3)
  return price
}


// Return a caption length that agrees with Twitter's calculations
export function captionLength (caption) {
  caption = caption.trimEnd().replace(/\r\n/g, "\n")
  const { weightedLength } = twitter.parseTweet(caption)
  return Math.max(weightedLength, caption.length)
}


// Converts a long caption into an array of tweets, each under 280 characters.
export function captionToTweets (caption) {
  const tweets = []
  const maxLength = 280

  caption = caption.trimEnd()

  while (caption.length) {
    const {
      weightedLength, // The length of the tweet, according to Twitter's rules
      validRangeEnd,  // The last character that can be included in the tweet
    } = twitter.parseTweet(caption)

    // There are a few edge cases where the weighted length is less than the
    // actual length of the caption. In those cases, we'll just use the actual
    // length of the caption.
    let endOfTweet = Math.max(weightedLength, caption.length)

    // Find the best place to split a long caption
    if (endOfTweet > maxLength) {
      const breakpoints = [
        caption.lastIndexOf('\n\n', validRangeEnd), // split between paragraphs
        caption.lastIndexOf('\n',   validRangeEnd), // break at end of a line
        max([                                   // break at end of a sentence
          caption.lastIndexOf('. ',   validRangeEnd - 1) + 1, // statement
          caption.lastIndexOf('? ',   validRangeEnd - 1) + 1, // question
          caption.lastIndexOf('! ',   validRangeEnd - 1) + 1, // yelling ;)
        ]),
        caption.lastIndexOf(' ',    validRangeEnd), // break between words
        validRangeEnd,                              // in case all else fails
      ]

      endOfTweet = breakpoints.find(i => i > 0)
    }

    const currentTweet = caption.substring(0, endOfTweet).trimEnd()
    tweets.push(currentTweet)
    let remainingCaption = caption.substring(endOfTweet)

    // Remove leading whitespace, but leave a leading space on a list item
    if (remainingCaption.match(/^\n [-+*•]/)) {
      remainingCaption = remainingCaption.replace(/^\n+/, '')
    } else {
      remainingCaption = remainingCaption.trimStart()
    }
    caption = remainingCaption
  }

  return tweets.filter(t => t.trim().length > 0)
}


export function normalizeCaption(text) {
  let newText = text.slice(0)

  // Don't allow more than 2 newlines in a row (social media platforms don't)
  newText = newText.replace(/\n( *\n)+/g, '\n\n')

  // Don't allow more than one space in a row
  newText = newText.replace(/ +/g, ' ')

  // Don't allow whitespace at the start of the line
  newText = newText.replace(/^ +/mg, '')

  return newText
}


// Converts "Dave Jonees<dave@foo.bar>" into "dave@foo.bar"
export function normalizeEmail(email) {
  if (typeof email !== 'string') return email
  email = email.trim().toLowerCase()
  return (email.match(/<([^>]+)>/) || ['', email])[1]
}


export function pluralCount(num, singular, plural) {
  plural = plural || singular + 's'
  return [num, num === 1 ? singular : plural].join(' ')
}


export function titleize(str) {
  if (typeof str !== 'string') return str
  if (str === 'linkedin') return 'LinkedIn'
  if (str.indexOf(' ') < 0) str = camelToWords(str)
  str = str.replace(/_/g, ' ') // for snake case text
  str = str.trim().toLowerCase()
  return str.replace(FIRST_LETTER, upperCaseFirstLetter)
}


// Trim whitespace from the start and end of each line (and the whole string)
export function trimLines(text) {
  if (typeof text !== 'string') return text
  return text.split('\n').map(line => line.trim()).join('\n').trim()
}


export function camelCase(str) {
  if (typeof str !== 'string') return str
  return str.replace(FIRST_LETTER_OF_SNAKE, (txt) => {
    return txt.charAt(1).toUpperCase()
  })
}


export function snakeCase(str) {
  if (typeof str !== 'string') return str
  return str.replace(FIRST_LETTER_OF_CAMEL, (txt) => {
    return '_' + txt.charAt(0).toLowerCase()
  })
}


function camelToWords(str) {
  if (typeof str !== 'string') return str
  return str.replace(FIRST_LETTER_OF_CAMEL, (txt) => {
    return ' ' + txt.charAt(0).toLowerCase()
  })
}

export function distanceBetween(a, b) {
  return levenshtein.get(a, b)
}


export function ordinalize(number) {
  if (Number.isNaN(number)) return number
  const num = number.toString()
  const firstDigit = num[0]
  const lastDigit = num.slice(-1)[0]

  // Special case for 10th-19th
  if (num.length === 2 && firstDigit === '1') {
    return num + 'th'
  }
  if (lastDigit === '1') return num + 'st'
  if (lastDigit === '2') return num + 'nd'
  if (lastDigit === '3') return num + 'rd'
                         return num + 'th'
}


export function formatPhone(str) {
  // Make sure it looks like a valid phone number before formatting it
  if (typeof str !== 'string') return str
  if (!str.match(/^[)(+.-\s\d/]+$/)) return str

  let nums = str.replace(/[^\d]/g, '')

  if (nums.length === 10) return insertDashes(nums, [   3, 3, 4])
  if (nums.length === 11) return insertDashes(nums, [1, 3, 3, 4])

  return str
}


function insertDashes(string, lengths) {
  let chunks = []
  let index = 0
  lengths.forEach(len => {
    chunks.push(string.substring(index, index + len))
    index += len
  })
  return chunks.join('-')
}


function upperCaseFirstLetter(str) {
  if (typeof str !== 'string') return str
  return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase()
}


export function validEmail(str) {
  return typeof str === 'string' && VALID_EMAIL.test(str)
}

import classNames from "classnames"

// Styled checkbox (that is still accessible)
export default function Checkbox ({children, onChange, checked, disabled, small, alignTop}) {

  function handleChange (e) {
    onChange()
  }

  return <>
    <label className={classNames("checkbox-component", {disabled, small, 'align-top': !!alignTop})}>
      <span className="checkbox-wrapper">
        <input type="checkbox" name="checkbox" onChange={handleChange} checked={checked || false} disabled={disabled}/>
        <span className="checkbox-control">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            aria-hidden="true"
            focusable="false"
          >
            <path
              fill="none"
              stroke="white"
              strokeWidth="4"
              d="M 5 11 L 10 16 L 22 2"
            />
          </svg>
        </span>
      </span>
      <span className={classNames("radio-label", {small})}>
        { children }
      </span>
    </label>


    <style jsx>{`
      .checkbox-component {
        display: grid;
        grid-template-columns: min-content auto;
        grid-gap: 0.4em;
        color: #333;
        font-size: 1.1rem;
        cursor: pointer;
        align-items: center;
      }

      .checkbox-component.align-top {
        align-items: start;
      }

      .small.checkbox-component {
        grid-gap: 0.4em;
      }

      input {
        opacity: 0;
        width: 1em;
        height: 1em;
      }

      .checkbox-control {
        display: inline-grid;
        width: 1em;
        height: 1em;
        border: 1px solid #999;
        border-radius: 2px;
        margin-top:3px;
      }

      .small .checkbox-control {
        margin-top: 0;
      }

      .checkbox-wrapper {
        display: grid;
        grid-template-areas: "checkbox";
      }

      .checkbox-wrapper > * {
        grid-area: checkbox;
      }

      .checkbox-control svg {
        transition: transform 100ms ease;
        transform: scale(0) translate(-5px, 5px);
        transform-origin: center;
        margin-top: 2px;
        margin-right: 1px;
      }

      .checkbox-wrapper input:checked + .checkbox-control {
        background-color: #3499CC;
        border-color: #3499CC;
      }

      .checkbox-wrapper input:checked + .checkbox-control svg {
        transform: scale(1);
        stroke-linecap: round;
        color: #666695;
      }

      .checkbox-wrapper input:focus-visible + .checkbox-control {
        box-shadow: 0 0 0.15rem 0.15rem #fff, 0 0 0.05rem 0.3rem currentColor;
      }

      .radio-label {
        margin-top: -0.1rem;
      }

      .disabled.checkbox-component {
        cursor: not-allowed;
      }
      .disabled .checkbox-control {
        background-color: #aaa;
      }
      .disabled .checkbox-control,
      .disabled .radio-label,
      .disabled .checkbox--disabled {
        color: #ccc;
      }
      .disabled .checkbox-control {
        filter: saturate(0) brightness(1.5);
      }

    `}</style>
  </>
}

/*
This class represents a date (without any time info or timezone concerns).
It should be treated as an immutable object (unlike the native Date class).
Unlike JS Dates, the months are a 1-based index (1 == January), and this class
uses the term `dayOfMonth` instead of `date` and `dayOfWeek` instead of `day`.

Usage:
    import SimpleDate from './SimpleDate'

    let date

    date = new SimpleDate()             // Defaults to today
    date = new SimpleDate( new Date() ) // Convert a JS Date into a SimpleDate
    date = new SimpleDate('2021-07-04') // Parse an ISO date string
    date = new SimpleDate( date )       // Clone an existing SimpleDate

    date = new SimpleDate('2021-07-04T06:30:21.891Z') // ISO8601 timestamp

    const t = 1625356800                // Seconds since unix epoch
    date = SimpleDate.fromUnix( t )     // Convert unix time to a SimpleDate

    date.year       // 2021
    date.month      // 7
    date.dayOfMonth // 4
    date.dayOfWeek  // 0 (Sunday)
    date.dayName    // 'Sunday'
    date.monthName  // 'July'
    date.valueOf()  // '2021-07-04'
    date.toString() // '2021-07-04'
    date.toUnix()   // 1625356800 (seconds since 00:00:00 UTC on 1 January 1970)

    // Comparisons
    let a = new SimpleDate('2021-07-04')
    let b = new SimpleDate('2021-08-01')

    a < b   // true
    a <= b  // true
    a > b   // false
    a >= b  // false

    // DO NOT USE
    a === b // ALWAYS FALSE
    a == b  // ALWAYS FALSE

    // Proper Equality Checks
    a.equals(b)                 // This is the correct way to check equality
    a.valueOf() == b.valueOf()  // This also works

    // SimpleDate can be used as a key (converts to ISO date string)
    date = new SimpleDate('2021-12-25')
    let hash = {}
    hash[date] = 'xmas'         // hash is now: {'2021-12-25': 'xmas'}

*/

const DATE_FORMAT = /^\d{4}-\d{2}-\d{2}$/
const ISO8601_FORMAT = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const DAY_NAMES = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thusday', 'Friday', 'Saturday']

export default class SimpleDate {
  year = 0 // 4-digit year
  month = 0 // 1-12
  dayOfMonth = 0 // 1-31
  dayOfWeek = -1 // 0-6 (where sunday is zero)

  monthName = ''
  dayName = ''
  _value = ''

  constructor (val) {
    if (val instanceof SimpleDate) {
      this._value = val.valueOf()
      this.year = val.year
      this.month = val.month
      this.dayOfMonth = val.dayOfMonth
      this.dayOfWeek = val.dayOfWeek
      this.monthName = val.monthName
      this.dayName = val.dayName
      return
    }

    if (typeof val === 'undefined') {
      const today = new Date() // Default to today (if no value was passed in)
      val = jsDate(today.getFullYear(), today.getMonth(), today.getDate())
    }

    let date

    if (val instanceof Date) {
      const year = val.getUTCFullYear()
      const month = val.getUTCMonth()
      const dayOfMonth = val.getUTCDate()
      date = jsDate(year, month, dayOfMonth)
    } else if (typeof val === 'string') {
      if (DATE_FORMAT.test(val)) {
        const [year, month, dayOfMonth] = val.split('-').map((s) => parseInt(s, 10))
        date = jsDate(year, month - 1, dayOfMonth)
      } else if (ISO8601_FORMAT.test(val)) {
        date = new Date(val)
      } else {
        throw Error(`Invalid SimpleDate - expected 'YYYY-MM-DD' (or ISO8601 string), but got ${JSON.stringify(val)}`)
      }
    } else {
      throw Error(`Invalid SimpleDate - expected 'YYYY-MM-DD', but got ${JSON.stringify(val)}`)
    }

    try {
      this._value = date.toISOString().split('T')[0]
    } catch (err) {
      throw Error('Invalid date value: ' + JSON.stringify(date) + ' - when creating SimpleDate with: ' + JSON.stringify(val))
    }

    const dayOfWeek = date.getUTCDay() // 0-based index into the day of the week
    const [year, month, dayOfMonth] = this._value.split('-').map((s) => parseInt(s, 10))
    this.year = year
    this.month = month
    this.dayOfMonth = dayOfMonth
    this.dayOfWeek = dayOfWeek
    this.monthName = MONTH_NAMES[month - 1]
    this.dayName = DAY_NAMES[dayOfWeek]
  }

  static fromUnix( secondsSinceUnixEpoch ) {
    return new SimpleDate( new Date(secondsSinceUnixEpoch * 1000) )
  }

  addDays (count) {
    if (typeof count !== 'number') throw Error('SimpleDate.addDays expected a number - got: ' + JSON.stringify(count))
    let newDate = simpleDate(this.year, this.month, this.dayOfMonth + count)
    return newDate
  }

  addMonths (count) {
    if (typeof count !== 'number') throw Error('SimpleDate.addMonths expected a number - got: ' + JSON.stringify(count))
    let newDate = simpleDate(this.year, this.month + count, this.dayOfMonth)
    // Make sure it didn't roll over to the next month
    if (newDate.dayOfMonth !== this.dayOfMonth) newDate = newDate.endOfPreviousMonth()
    return newDate
  }

  addYears (count) {
    if (typeof count !== 'number') throw Error('SimpleDate.addYears expected a number - got: ' + JSON.stringify(count))
    let newDate = simpleDate(this.year + count, this.month, this.dayOfMonth)
    // Make sure it didn't roll over to the next month
    if (newDate.dayOfMonth !== this.dayOfMonth) newDate = newDate.endOfPreviousMonth()
    return newDate
  }

  daysBetween (other) {
    if (!other) throw Error('Expected a SimpleDate param when calling `daysBetween`')
    const oneDay = 24 * 60 * 60 * 1000
    const diff = other.toNativeDate() - this.toNativeDate()
    return Math.round( diff / oneDay )
  }

  monthsBetween (other) {
    if (!other) throw Error('Expected a SimpleDate param when calling `daysBetween`')
    const oneMonth = 30.5
    return Math.round(this.daysBetween(other) / oneMonth)
  }

  equals (other) {
    if (!other) return false
    return this.toString() === other.toString()
  }

  toNativeDate () {
    return jsDate(this.year, this.month - 1, this.dayOfMonth)
  }

  toEnglish () {
    return `${this.monthName.substring(0, 3)} ${this.dayOfMonth}, ${this.year}`
  }

  toString () {
    return this._value
  }

  toUnix () {
    return Math.round(this.toNativeDate().getTime() / 1000)
  }

  toJSON () {
    return this.toString()
  }

  valueOf () {
    return this.toString()
  }

  //============================================================================

  endOfPreviousMonth () {
    // Back up to the end of the previous month
    return simpleDate(this.year, this.month, 1).addDays(-1)
  }
}

// NOTE: In a simple date the month is 1-based (ya know... like people do)
function simpleDate (year, month, dayOfMonth) {
  const date = new Date(Date.UTC(year, month - 1, dayOfMonth))
  // Stupid javascript uses a zero-based index for months :P
  return new SimpleDate(date)
}

// Note in a JS Date the month has a 0-based index :P
function jsDate (year, month, dayOfMonth) {
  // Stupid javascript uses a zero-based index for months :P
  return new Date(Date.UTC(year, month, dayOfMonth))
}

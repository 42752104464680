import { IS_DEVELOPMENT } from '../../env'
import {get} from './api'
import useSWR, { mutate } from 'swr'
import { reloadSessionOrg } from './session'
import { sendMessage } from './useMessage'


// FIXME: Need to keep a count on each URL, and decrement each time that a
// component is dismounted. That way we can avoid firing mutute() on URLs that
// are no longer needed.

let allUrls = new Set()
let initialLoad = {}

export default function useApi(url, options) {

  if (url === '/org') {
    const message = `const [org] = useApi('/org') is deprecated -- should be: const org = useCurrentOrg()`
    if (IS_DEVELOPMENT) {
      throw Error(message)
    } else {
      console.warn(message)
    }
  }

  const { data, mutate: scopedMutate, error } = useSWR(url, async (url) => {
    if (url.includes('no-refresh') && initialLoad[url]) {
      let cachedData = null
      await mutate(url, async (data) => {
        cachedData = data
        return cachedData
      }, false)
      return cachedData
    }

    const data = await fetchData(url)
    initialLoad[url] = true
    return data
  })

  if (!url.includes('no-refresh')) {
    allUrls.add(url)
  }

  // Create a function for the UI to make a local (optimistic update to the data)
  const setLocalData = (data) => {
    if (data) {
      scopedMutate(data, false)
    } else {
      scopedMutate()
    }
  }

  return [data, setLocalData, error]


  async function fetchData (url) {
    // console.log('----> fetching data from ' + url)
    let res = null

    try {
      res = await get(url)

      if (res.status === 401) {
        console.log('401 error for useApi fetch on ' + url)
        return
      }

      if (res.status >= 400 && res.status < 500) {
        const errorMessage = `${res.status} response from API ${url}`
        // console.warn(`${res.status} response from API ${url}`)
        throw Error(errorMessage)
      }

      if (res.status >= 500) {
        let body = ''
        try {
          const json = await res.clone().json()
          console.log(json)
          body = json.userMessage || json.message || ''
          if (IS_DEVELOPMENT) body += '\n' + json.stack[1]
          console.log('error is json')
        } catch (parseError) {
          console.log('Error parsing JSON error from API response')
        }
        if (!body) console.log('error is text')
        body ||= await res.clone().text()
        throw Error(`${res.status} error from API for ${url} - ${body}`)
      }

      return res.json()
    } catch (err) {
      console.warn('Got an error when talking to the API')
      console.error(err)
      if (options?.showErrors) {
        const errMessage = (err.message || 'Error talking to the API')
        sendMessage('error', errMessage)
      }
      throw err // Rethrow so SWR can return the error to the component
    }
  }
}

export async function refreshApiData (url) {
  if (url) {
    console.warn('-> FOCUSED INVALIDATION: ' + url)
    await mutate(url)
  } else {
    // Refresh ALL the data
    await reloadSessionOrg()
    await Promise.all( Array.from(allUrls).map(url => mutate(url) ))
  }
}

import { useCallback, useState } from "react";
import { getSessionOrg, reloadSessionOrg } from "./session";
import { useMessage } from "./useMessage";


export default function useCurrentOrg() {

  const [currentOrg, setCurrentOrg] = useState( getSessionOrg() )

  useMessage('sessionOrgLoaded', useCallback(() => setCurrentOrg( getSessionOrg() ), []))

  return currentOrg
}


export async function refreshCurrentOrg() {
  reloadSessionOrg()
}

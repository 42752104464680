import rrulePkg from 'rrule'
const { RRule } = rrulePkg
import SimpleDate from "./SimpleDate.js"
import { ordinalize } from "./string.js"



export function rruleToNextDate(rrule, {today} = {}) {
  if (!rrule) return

  let rruleOpts = null
  try {
    rruleOpts = RRule.parseString(rrule)
  } catch (err) {
    throw Error(`Invalid rrule passed to rruleToNextDate: ${JSON.stringify(rrule)} -- ${err}`)
  }
  if (!rruleOpts) return

  today = today || new SimpleDate()
  today = today
  const minDate = today.addDays(-1)   // Back up one day to avoid UTC offset issues
  const maxDate = today.addMonths(13) // 12 months isn't enough in some cases

  rruleOpts.dtstart = today.toNativeDate()
  const ruleset = new RRule(rruleOpts)

  const dates = ruleset.between(
    minDate.toNativeDate(),
    maxDate.toNativeDate(),
  ).map(d => new SimpleDate(d))

  // Need to filter out past dates, since minDate was slightly in the past
  const futureDates = dates.filter(d => d > today || d.equals(today))

  return futureDates[0]
}


// RRULE:FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=14
// RRULE:FREQ=YEARLY;BYMONTH=1;BYDAY=-1MO (last monday)
export function rruleToShortEnglish (rrule) {
  if (!rrule) return rrule
  let text = []

  const [rrulePart, offsetPart] = rrule.split('|')

  const chunks = rrulePart.replace(/RRULE:/, '').split(';')
  for (const c of chunks) {
    const [tag, val] = c.split('=', 2)
    if (tag === 'FREQ') {
      if (val !== 'YEARLY') {
        text.push('ERROR: Only YEARLY is supported')
      }
    }
    else if (tag === 'BYMONTH') {
      const dateString = `2020-${val.padStart(2, '0')}-01`
      const d = new SimpleDate(dateString)
      text.push( d.monthName.substring(0, 3) )
    }
    else if (tag === 'BYDAY') {
      const m = val.match(/^([+-][1-4]+)([a-z]{2})$/i)
      if (!m) {
        text.push(`ERROR: Can't parse ${c}`)
        continue
      }
      text = [
        byDayPositionText(m[1]),
        byDayNameText(m[2]).substring(0, 3),
        'of',
        ...text,
      ]
    }
    else if (tag === 'BYMONTHDAY') {
      text.push( ordinalize(val) )
    }
    else {
      text.push( `UNSUPPORTED RRULE TAG: ${c}` )
    }
  }

  if (offsetPart) {
    const [_offsetLabel, offsetText] = offsetPart.split(':')
    text.push(`${offsetText[0]}${offsetText.substring(1)}`)
  }

  return text.join(' ')
}


// RRULE:FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=14
// RRULE:FREQ=YEARLY;BYMONTH=1;BYDAY=-1MO (last monday)
export function rruleToEnglish (rrule) {
  if (!rrule) return rrule
  const text = []
  const chunks = rrule.replace(/RRULE:/, '').split(';')
  for (const c of chunks) {
    const [tag, val] = c.split('=', 2)
    if (tag === 'FREQ') {
      if (val !== 'YEARLY') {
        text.push('ERROR: Only YEARLY is supported')
      } else {
        text.push( 'every' )
      }
    }
    else if (tag === 'BYMONTH') {
      const dateString = `2020-${val.padStart(2, '0')}-01`
      const d = new SimpleDate(dateString)
      text.push( d.monthName )
    }
    else if (tag === 'BYDAY') {
      const m = val.match(/^([+-][1-4]+)([a-z]{2})$/i)
      if (!m) {
        text.push(`ERROR: Can't parse ${c}`)
        continue
      }
      text.push( `on the` )
      text.push(byDayPositionText(m[1]))
      text.push(byDayNameText(m[2]))
    }
    else if (tag === 'BYMONTHDAY') {
      text.push( `on the ${ordinalize(val)}` )
    }
    else {
      text.push( `UNSUPPORTED RRULE TAG: ${c}` )
    }
  }
  return text.join(' ')
}


const VALID_SHORT_RECURRING_DATE = /^([a-z]+) ([0-9]+)(?:st|th|rd|nd)?$/i
const VALID_SHORT_RECURRING_DAY_OF_WEEK = /^(last|[0-9]+)(?:st|th|rd|nd)? ([a-z]+) (?:of|in) ([a-z]+)(?: ([-+])\s*([0-9]+)\s*(?:d|day|days?))?$/i
export function shortEnglishToRRule (val) {
  if (!val) return val

  try {
    return englishToRRule(val)
  } catch (err) {
    // Swallow error to give this new englishToRRule a chance
  }

  const m1 = val.trim().match(VALID_SHORT_RECURRING_DATE)
  const m2 = val.trim().match(VALID_SHORT_RECURRING_DAY_OF_WEEK)


  if (!m1 && !m2) throw Error(`Can't parse recurring date text: ${val}`)

  if (m1) {
    const monthName = m1[1]
    const dayOfMonth = m1[2]

    return [
      `RRULE:FREQ=YEARLY`,
      `BYMONTH=${monthNum(monthName)}`,
      `BYMONTHDAY=${dayOfMonth}`,
    ].join(';')
  }

  if (m2) {
    const dayOfMonth = m2[1]
    const dayOfWeek = m2[2]
    const monthName = m2[3]
    const offsetDirection = m2[4]
    const offsetDays = m2[5] ? parseInt(m2[5], 10) : 0

    const weekCount = dayOfMonth === 'last' ? '-1' : `+${dayOfMonth}`
    const validWeekNum = '-1,+1,+2,+3,+4'.split(',').includes(weekCount)
    if (!validWeekNum) {
      throw Error(`Can't parse recurring date text: ${val} -- bad week number ${weekCount}`)
    }

    const dayAbbrev = dayOfWeek.substring(0, 2).toUpperCase()
    let rrule = [
      `RRULE:FREQ=YEARLY`,
      `BYMONTH=${monthNum(monthName)}`,
      `BYDAY=${weekCount}${dayAbbrev}`,
    ].join(';')

    if (offsetDays) {
      rrule = `${rrule}|OFFSET:${offsetDirection}${offsetDays}d`
    }

    return rrule
  }

}


const VALID_RECURRING_DATE = /^every ([a-z]+) on the (last|[0-9]+)(?:st|th|rd|nd)?(?: ([a-z]+))?$/i
export function englishToRRule (val) {
  if (!val) return val

  const m = val.trim().match(VALID_RECURRING_DATE)


  if (!m) throw Error(`Can't parse recurring date text: ${val}`)

  const monthName = m[1]
  const dayOfMonth = m[2]
  const dayOfWeek = m[3]

  if (!dayOfWeek) {
    return [
      `RRULE:FREQ=YEARLY`,
      `BYMONTH=${monthNum(monthName)}`,
      `BYMONTHDAY=${dayOfMonth}`,
    ].join(';')
  }

  const weekCount = dayOfMonth === 'last' ? '-1' : `+${dayOfMonth}`
  const validWeekNum = '-1,+1,+2,+3,+4'.split(',').includes(weekCount)
  if (!validWeekNum) {
    throw Error(`Can't parse recurring date text: ${val} -- bad week number ${weekCount}`)
  }

  const dayAbbrev = dayOfWeek.substring(0, 2).toUpperCase()
  return [
    `RRULE:FREQ=YEARLY`,
    `BYMONTH=${monthNum(monthName)}`,
    `BYDAY=${weekCount}${dayAbbrev}`,
  ].join(';')
}


// Private helper functions...


function monthNum (month) {
  const m = month.toLowerCase().trim()
  if (m === 'jan' || m === 'january')   return 1
  if (m === 'feb' || m === 'february')  return 2
  if (m === 'mar' || m === 'march')     return 3
  if (m === 'apr' || m === 'april')     return 4
  if (m === 'may' || m === 'may')       return 5
  if (m === 'jun' || m === 'june')      return 6
  if (m === 'jul' || m === 'july')      return 7
  if (m === 'aug' || m === 'august')    return 8
  if (m === 'sep' || m === 'september') return 9
  if (m === 'oct' || m === 'october')   return 10
  if (m === 'nov' || m === 'november')  return 11
  if (m === 'dec' || m === 'december')  return 12

  throw Error(`Invalid month name ${month}`)
}


function byDayNameText (dayAbrrev) {
  const d = dayAbrrev.toLowerCase().trim()
  if (d === 'mo') return 'Monday'
  if (d === 'tu') return 'Tuesday'
  if (d === 'we') return 'Wednesday'
  if (d === 'th') return 'Thursday'
  if (d === 'fr') return 'Friday'
  if (d === 'sa') return 'Saturday'
  if (d === 'su') return 'Sunday'

  throw Error(`Invalid day name ${dayAbrrev}`)
}


function byDayPositionText (val) {
  if (val === '+1') return ordinalize( parseInt(val.substring(1), 10 ))
  if (val === '+2') return ordinalize( parseInt(val.substring(1), 10 ))
  if (val === '+3') return ordinalize( parseInt(val.substring(1), 10 ))
  if (val === '+4') return ordinalize( parseInt(val.substring(1), 10 ))
  if (val === '-1') return 'last'

  return (`Invalid BYDAY value ${val}`)
}

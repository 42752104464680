import { sortBy } from "@/lib/array";
import { get, post } from "./api"
import { jazzlinkMode, jazzlinkStorageGetItem, jazzlinkStorageSetItem } from "./misc"
import { sendMessage } from "./useMessage"

const JAZZLINK_SESSION_KEY = 'jazzlink-session'
const DEBUG_SESSION = false


let _sessionData = {}
let _currentOrg = null
let _currentUser = null


export function getSessionOrg () {
  return _currentOrg
}


export function getSessionUser () {
  return _currentUser
}


export function getSessionHeaders () {
  const token = _sessionData.token || ''
  const orgId = _sessionData.orgId || ''

  const headers = { 'Content-Type': 'application/json' }
  if (token) headers['X-Social-Jazz-Auth-Token'] = token
  if (orgId) headers['X-Social-Jazz-Auth-Org']   = orgId

  // Make it possible to use org specific components backstage
  if (typeof window !== 'undefined' && window.location.pathname.indexOf('/backstage/orgs/') === 0) {
    headers['X-Social-Jazz-Auth-Org'] = window.location.pathname.split('/')[3]
  }

  return headers
}


export async function loadSession() {
  if (DEBUG_SESSION) console.log('Loading session...')

  await loadSessionData()

  await loadCurrentUser()

  if (!_currentUser) {
    await clearSession()
    return
  }

  if (_sessionData.orgId) {
    await loadCurrentOrg()
  } else {
    await defaultToFirstOrg()
  }
}


export async function login ({loginKey, loginCode, loginId}) {
  const data = {loginKey, loginCode, loginId}
  const res = await post(`/login`, data)

  if (res.status === 200) {
    const {redirect, token, orgId} = await res.json()
    await saveSession({token, orgId})
    // Reload the session to fetch the current user and org
    await loadSession()
    return {redirect}
  } else {
    let errorMessage = `Unexpected Error (${res.status})`
    try {
      const {status} = await res.json()
      errorMessage = status
    } catch (e) {
      console.warn('Failed to parse error message')
    }
    throw Error(errorMessage)
  }
}


export async function logout () {
  await clearSession()
  await post('/logout', {})
}


export async function reloadSessionOrg () {
  if (DEBUG_SESSION) console.log('reloadSessionOrg()')
  await loadCurrentOrg()
}


export async function switchToOrg (org) {
  if (DEBUG_SESSION) console.log('Switching to org', {id: org?.id})
  const orgId = org?.id || ''

  if (orgId) {
    // When switching orgs, it's important to wait for the session to save,
    // because the app might reload the page before the save finishes, and then
    // the app will revert to the previous org.
    await saveSession({..._sessionData, orgId})
    await loadCurrentOrg()
  } else {
    defaultToFirstOrg()
  }
}


//==============================================================================
// Private Functions
//==============================================================================


async function clearSession() {
  if (DEBUG_SESSION) console.log('clearSession()')

  _currentOrg = null
  _currentUser = null
  _sessionData = {}

  await saveSession(_sessionData)

  sendMessage('sessionCleared')
}


// If there is no current org, default to the first one in the list
async function defaultToFirstOrg() {
  if (DEBUG_SESSION) console.log('defaultToFirstOrg')
  let orgs = await loadOrgList()

  // Sort orgs to prioritize ones that haven't been cancelled
  orgs = sortBy(orgs, ['cancelationDate', 'createdAt'])

  if (orgs.length) {
    await switchToOrg(orgs[0])
  }
}


async function loadCurrentOrg() {
  const orgId = _sessionData.orgId || ''
  if (DEBUG_SESSION) console.log('* loadCurrentOrg:', orgId)

  if (!orgId) {
    _currentOrg = null
    sendMessage('sessionOrgLoaded')
    return
  }

  try {
    const res = await get(`/orgs/${orgId}`)

    // This happen when a user removed from an org or a (demo) org is deleted
    if (res.status === 403) {
      console.log('403 response from API when loading current org - defaultToFirstOrg')
      await defaultToFirstOrg()
      return
    }

    if (res.status >= 400 && res.status < 500) {
      const errorMessage = `${res.status} response from API when loading current org`
      // console.warn(`${res.status} response from API`)
      throw Error(errorMessage)
    }

    if (res.status >= 500) {
      console.log(`${res.status} error loading org from API`)
      throw Error(`${res.status} error when loading org from API for`)
    }

    _currentOrg = await res.json()
  } catch (err) {
    console.warn(`Got an error when trying to load the current org (${orgId})`)
    console.error(err)
    // throw err // Rethrow so SWR can return the error to the component
    _currentOrg = null
  }

  if (DEBUG_SESSION) console.log('-> firing message: sessionOrgLoaded', _currentOrg)
  sendMessage('sessionOrgLoaded')
}


async function loadCurrentUser() {
  if (DEBUG_SESSION) console.log('* loadCurrentUser')

  let user = null

  try {
    const res = await get('/auth/isLoggedIn')

    if (res.status === 200) {
      user = (await res.json()).user
      if (DEBUG_SESSION) console.log('user loaded from API', user)
    } else {
      console.warn(`${res.status} response from API when loading current user`)
    }
  } catch (err) {
    console.warn(`Got an error when trying to load the current user`)
    console.error(err)
  } finally {
    const sameUser = _currentUser?.id === user?.id
    if (!sameUser) {
      _currentUser = user
      _currentOrg = null

      if (DEBUG_SESSION) console.log('-> firing message: sessionUserLoaded', _currentUser)
      sendMessage('sessionUserLoaded')
    }
  }
}


// Get a list of all the orgs that this user has an account with
async function loadOrgList() {
  const res = await get('/orgs')
  let orgs = []

  if (res.status === 200) {
    orgs = await res.json()
  } else {
    console.warn('Failed to load orgs list')
    console.warn(res)
  }

  return orgs
}


//------------------------------------------------------------------------------
// The app used to store the current org id in a cookie called 'org', but this
// created some very quirky code in the [...path].js endpoint, and it also
// didn't allow for having multiple (different) orgs open in different browser
// tabs. So we've switch to putting the orgId into sessionStorage (and also
// localStorage, for persistence across sessions).
//------------------------------------------------------------------------------


async function loadOrgIdFromLegacyCookie () {
  if (DEBUG_SESSION) console.log('Loading org id from legacy cookie...')
  const res = await fetch('/api/legacy-org-id')
  if (res.status === 200) {
    const orgId = await res.text()
    if (DEBUG_SESSION) console.log('Org id from legacy cookie:', orgId)
    if (orgId) {
      await saveSession({..._sessionData, orgId}) // in localStorage
      await removeLegacyOrgIdCookie()
    }
  }
}


// Load the session data (auth token and current orgId) from: sessionStorage,
// localStorage, cookie, or jazzlink storage.
async function loadSessionData () {
  if (jazzlinkMode()) {
    try {
      const sessionData = await jazzlinkStorageGetItem(JAZZLINK_SESSION_KEY)
      if (!sessionData) return
      const [token, orgId] = sessionData.split(':', 2)
      _sessionData = {token, orgId}
      if (DEBUG_SESSION) console.log('Loaded (jazzlink) session', redactedSessionData())
    } catch (error) {
      console.log('Error loading jazzlink session')
      console.error(error)
    }
  } else {
    try {
      let orgId = sessionStorage.getItem('orgId') || localStorage.getItem('orgId')
      _sessionData.orgId = orgId || ''

      // TODO: This next line can be removed any time after Jan 1st 2024
      if (!orgId) await loadOrgIdFromLegacyCookie()

      // NOTE: When using non-jazzlink auth, the _sessionData.token will be
      // empty, because it is stored in an HTTP-only cookie, and we don't pass
      // it to the client app. Instead, the code in `/api/[...path].js` will
      // load the token (from the cookie) and add it to the request sent to the
      // API server.

      if (DEBUG_SESSION) console.log('Loaded (normal) session', redactedSessionData())
    } catch (error) {
      console.log('Error loading session')
      console.error(error)
    }
  }
}


// Remove legacy `org` cookie (if it still exists)
async function removeLegacyOrgIdCookie () {
  if (DEBUG_SESSION) console.log('TODO: REMOVE this early return')

  console.log('Removing legacy org id cookie...')
  const res = await fetch('/api/remove-legacy-org-id', {method: 'POST'})
  if (res.status !== 200) {
    console.log('Error removing legacy cookie')
    console.error(res)
  }
}


async function saveSession(newSessionData) {

  const newToken = newSessionData?.token || ''
  const newOrgId = newSessionData?.orgId || ''

  const changed = (
    _sessionData.token !== newToken ||
    _sessionData.orgId !== newOrgId
  )

  if (!changed) return

  _sessionData.token = newToken
  _sessionData.orgId = newOrgId

  if (jazzlinkMode()) {
    const jazzLinkData = [_sessionData.token, _sessionData.orgId].join(':')
    await jazzlinkStorageSetItem(JAZZLINK_SESSION_KEY, jazzLinkData)
  } else {
    // NOTE: Not putting token into sessionStorage, because will be passed to
    // the API via an HTTP-only cookie (when not in jazzlink mode)

    // Put current org id into session storage so it can survive a page refresh
    sessionStorage.setItem('orgId', _sessionData.orgId)

    // Keep track of which org id to use the next time they launch the app
    localStorage.setItem('orgId', _sessionData.orgId)
  }
}


function redactedSessionData () {
  const currentToken = _sessionData.token || ''

  const redactedToken = currentToken ? (
    currentToken.substring(0, 4) +
    '...[REDACTED]...' +
    currentToken.substring(currentToken.length - 4)
  ) : ''

  return {
    ..._sessionData,
    token: redactedToken,
  }
}

import { login } from '../src/session'
import { sendMessage, useMessage } from '@/src/useMessage'
import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useAppRouter } from '@/src/useAppRouter'


const LOGIN_CODE_LENGTH = 4

export default function LoginCodeForm ({loginId, onComplete, noRedirect}) {

  const router = useAppRouter()
  const inputRef = useRef()
  const [loginCode, setLoginCode] = useState('')
  const [error, setError] = useState('')

  useEffect(focusLoginCodeInput, [])

  useMessage('focusLoginCode', () => {
    focusLoginCodeInput()
  })

  function focusLoginCodeInput () {
    if (inputRef.current) inputRef.current.focus()
  }

  const digits = loginCode.split('')
  while (digits.length < LOGIN_CODE_LENGTH) {
    digits.push('')
  }

  return <>
    <form onSubmit={handleSubmit}>
      <input type="hidden" name="loginId" value={loginId}/>

      <label>
        <input
          ref={inputRef}
          className="hidden-login-code-input"
          autoComplete="off"
          type="text"
          pattern="[0-9]*"
          inputMode="numeric"
          name="loginCode"
          value={loginCode}
          onInput={loginCodeChanged}
          data-testid="login-code-input"
        />

        <div className="digits">
          {
            digits.map((digit, i) => (
              <div key={i} className={classNames('digit', {current: i === loginCode.length})}>
                <div className="fake-cursor"></div>
                {digit}
              </div>
            ))
          }
        </div>
      </label>
    </form>

    { !!error &&
      <>
        <p className="error">
          {error}
        </p>
      </>
    }

    <style jsx>{`
      .hidden-login-code-input {
        position: absolute;
        opacity: 0;
        width: 100px;
        z-index: -1;
        border: none;
        color: transparent;
        box-shadow: none;
      }
      .digits {
        background-color: white;
        display: flex;
        justify-content: center;
        margin: auto;
        color: #aaa;
      }
      .fake-cursor {
        position: absolute;
        left: 50%;
        top: 25%;
        bottom: 25%;
        width: 0;
        border-left: 2px solid #333;
        animation: blink 1000ms infinite;
        display: none;
      }
      .hidden-login-code-input:focus + .digits {
        color: #333;
      }
      .hidden-login-code-input:focus + .digits .current {
        border: 3px solid #6FBE44;
      }
      .hidden-login-code-input:focus + .digits .current .fake-cursor {
        display: block;
      }
      @keyframes blink {
        0% { opacity: 0 }
        25% { opacity: 1 }
        75% { opacity: 1 }
        100% { opacity: 0 }
      }
      .digit {
        background-color: #eee;
        margin: 0 0.25rem;
        border: 1px solid #ccc;
        min-width: 4rem;
        min-height: 5rem;
        font-size: 3rem;
        padding: 0.2rem 1rem 0;
        text-align: center;
        position: relative;
      }
      .error {
        color: red;
        display: inline-block;
        margin: 1rem 0;
      }
    `}</style>
  </>


  function loginCodeChanged (e) {
    setError('')
    const rawValue = e.currentTarget.value
    const value = rawValue.trim().substring(0, LOGIN_CODE_LENGTH).replace(/[^0-9]/g, '')
    setLoginCode(value)

    if (value.length === LOGIN_CODE_LENGTH) {
      submit(value)
    }
  }


  async function submit(value) {
    sendMessage('loading', true)

    try {
      const {redirect} = await login({loginId, loginCode: value})
      if (redirect && !noRedirect) router.push(redirect)
      if (onComplete) onComplete()
    } catch (err) {
      setLoginCode('')
      sendMessage('loading', false)
      setError(err.message)
    }
  }


  function handleSubmit (e) {
    e.preventDefault()
  }

}

import classNames from "classnames"
import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"


export default function UserInterfacePointer ({onClose, element}) {

  const [mounted, setMounted] = useState(false)
  const [started, setStarted] = useState(false)
  const [renderCount, setRenderCount] = useState(0)
  const [done, setDone] = useState(false)

  const closeHandler = useRef(onClose)


  useEffect(() => {
    setMounted(true)
  }, [setMounted])


  // Scroll the element into view if it's not already
  useEffect(() => {
    if (!element) return
    if (!mounted) return

    const rect = element.getBoundingClientRect()

    // Check if the element is already fully visible (in the viewport)
    if (rect.top > 0 && rect.left > 0 && rect.bottom < window.innerHeight && rect.right < window.innerWidth) {
      setStarted(true)
    } else {
      // In testing, it seems that the scrollIntoView() method doesn't work
      // unless there is at least an 18ms delay before it is called. To be safe,
      // we'll wait a bit longer than that before calling it.
      const scrollStartDelay = 100
      setTimeout(() => { element.scrollIntoView({behavior: 'smooth', block: 'center'}) }, scrollStartDelay)

      // Wait so the element enough has time to (smooth) scroll into view
      const smoothScrollDelay = 600 // Rough estimate based on testing
      setTimeout(() => setStarted(true), scrollStartDelay + smoothScrollDelay)
    }

  }, [element, mounted, setStarted])


  // Create timers to fade out and close the highlight after animation is done
  useEffect(() => {
    if (!started) return

    const timer1 = setTimeout(() => setDone(true), 1500)
    const timer2 = setTimeout(closeHandler.current, 1900)

    // Trigger repeated re-rendering, in case the element moves (scrolls)
    const timer3 = setInterval(() => setRenderCount(v => v + 1), 250)

    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
      clearInterval(timer3)
    }
  }, [started])


  let left   = 0
  let top    = 0
  let right  = '100vw'
  let bottom = '100vh'

  if (element && mounted && started) {
    // Check if the element is in the viewport
    const rect = element.getBoundingClientRect()

    left   = Math.round(rect.left + window.scrollX)
    top    = Math.round(rect.top  + window.scrollY)
    right  = Math.round(left + rect.width)
    bottom = Math.round(top + rect.height)
  }

  const topY    = `${top}px`
  const leftX   = `${left}px`
  const bottomY = `calc( 100% + ${bottom}px )`
  const rightX  = `calc( 100% +  ${right}px )`

  return <>
    { createPortal(
      <div className={classNames("wrapper", {mounted, done})}>
        <div className="background">
          <div className="background-top"    style={{transform: `translate( 0, ${topY}   )`}}/>
          <div className="background-bottom" style={{transform: `translate( 0, ${bottomY})`}}/>
          <div className="background-left"   style={{transform: `translate( ${leftX},  0 )`}}/>
          <div className="background-right"  style={{transform: `translate( ${rightX}, 0 )`}}/>
        </div>
      </div>
    , document.body) }


    <style jsx>{`
      .wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        pointer-events: none;
        transition: opacity 400ms;
      }
      .wrapper.done {
        opacity: 0;
      }
      .background {
        opacity: 0.7;
        transition: opacity 900ms;
        z-index: -3;
      }
      .background-top,
      .background-bottom,
      .background-left,
      .background-right {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: black;
        pointer-events: all;
        transition: transform 800ms;
        opacity: 1;
      }
      .background-top{    top: -100vh; }
      .background-bottom{ top: -100vh; transform: translate(0, 200%) }
      .background-left{  left: -100vw; }
      .background-right{ left: -100vw; transform: translate(200%, 0) }
    `}</style>
  </>

}
import { useRouter } from "next/router";

const DATE = /^\d{4}-\d{2}-\d{2}$/

const POPOVERS = [
  "calendar",
  "catalog",
  "settings",
  "edit",
  "create",
]

const SETTING_MENUS = [
  "account",
  "brand",
  "captions",
  "help",
  "notifications",
  "social",
  "subscription",
  "topics",
  "users",
]

export function useAppRouter () {
  const router = useRouter()

  //----------------------------------------------------------------------------
  // Enhance the router with `path()` and `goto()` methods, to make routing
  // nicer and to keep the routing logic in one place. For example:
  //
  //    router.goto('account')
  //----------------------------------------------------------------------------

  router.path = function (route, queryParams) {
    let query = queryParams || {}
    let pathname = '/'

    // By default, try to stay on the existing date
    if (!query.date && router.query.date) {
      query.date = router.query.date
    }

    if (DATE.test(route)) {
      query.date = route.toString()
    }

    if (POPOVERS.includes(route)) {
      query.popover = route
    }

    if (SETTING_MENUS.includes(route)) {
      query.popover = 'settings'
      query.menu = route
    }

    return {pathname, query}
  }


  router.pathIs = function (route) {
    const current = router.path(route)

    return (
      current.pathname      === router.pathname &&
      current.query.popover === router.query.popover &&
      current.query.menu    === router.query.menu
    )
  }


  router.goto = function (route, query) {
    const path = router.path(route, query)
    router.push(path)
  }

  return router
}